console.log('calendar is initialing...')

Object.assign(Date.prototype, {
    format(fmt) { //author: meizz   
        var o = {
            "M+": this.getMonth() + 1, //月份   
            "d+": this.getDate(), //日   
            "h+": this.getHours(), //小时   
            "m+": this.getMinutes(), //分   
            "s+": this.getSeconds(), //秒   
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度   
            "S": this.getMilliseconds() //毫秒   
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    },
    getDateName(realtime) {
        let td = new Date(),
            d = new Date(this.getFullYear(), this.getMonth(), this.getDate()),
            n
        td = new Date(td.getFullYear(), td.getMonth(), td.getDate())
        n = (d - td) / 1000 / 60 / 60 / 24;
        if (n < -5) {
            return this.format(realtime || 'yyyy-MM-dd')
        }
        if (n < -2 && n >= -5) {
            return -n + '天前'
        }
        if (n < -1) {
            return '前天'
        }

        if (n < 0) {
            return '昨天'
        }

        if (n == 0) {
            return '今天'
        }
        if (n < 2) {
            return '明天'
        }
        if (n < 3) {
            return '后天'
        }
        return n + '天后'
    },
    getTimeState() {
        let h = this.getHours()
        if (h < 1) {
            return '午夜'
        }
        if (h < 6) {
            return '凌晨'
        }
        if (h < 9) {
            return '早上'
        }
        if (h < 12) {
            return '上午'
        }
        if (h < 13) {
            return '中午'
        }
        if (h < 17) {
            return '下午'
        }
        if (h < 19) {
            return '傍晚'
        }
        return '晚上'
    },
})


function Calendar(d = new Date()) {
    this.date = d
    this.today = new Date(d)
    this.setDate(d.getDate())
    this.setYear(d.getFullYear())

    const weekday_CN = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
    const weekday_EN = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    this.getWeekday = function (lang) {
        switch (lang) {
            case 'en':
                return weekday_EN[this.curWeekday];
            default:
                return weekday_CN[this.curWeekday];
        }
    }
}

Calendar.prototype = {
    getDateName(d) {
        return new Date(d).getDateName()
    },
    getTimeState() {
        return new Date().getTimeState()
    },
    getWeekday(lang) {
        return this.getWeekday(lang)
    },
    setDate(d) {
        this.date.setDate(d)
        this.curDate = this.date.getDate()
        this.curMonth = this.date.getMonth()
        this.curYear = this.date.getFullYear()
        this.curWeekday = this.date.getDay()

        return this
    },
    setMonth(m) {
        this.date.setMonth(m)
        this.curMonth = this.date.getMonth()
        this.curYear = this.date.getFullYear()
        let d = new Date(this.date)
        d.setDate(1)
        this.startWeekday = d.getDay()
        d.setMonth(this.curMonth + 1)
        d.setDate(0)
        this.curMonthCount = d.getDate()
        d.setDate(0)
        this.lastMonthCount = d.getDate()

        return this
    },
    setYear(y) {
        this.date.setFullYear(y)
        this.curYear = this.date.getFullYear()
        this.setMonth(this.date.getMonth())

        return this
    },
    getOneWeek(simple) {
        let week = [],
            startDay = this.curDate - this.curWeekday,
            preMonthDate = new Date(this.date),
            nextMonthDate = new Date(this.date),
            curMonthDate = new Date(this.date),
            isNextMonth = false

        preMonthDate.setMonth(preMonthDate.getMonth() - 1)
        nextMonthDate.setMonth(nextMonthDate.getMonth() + 1)

        for (let i = 0; i < 7; i++, startDay++) {
            if (startDay <= 0) {
                let day = this.lastMonthCount + startDay
                preMonthDate.setDate(day)
                week.push({
                    day: simple ? 0 : day,
                    date: preMonthDate.format('yyyy-MM-dd')
                })
            } else if (startDay > this.curMonthCount) {
                isNextMonth = true
                nextMonthDate.setDate(1)
                week.push({
                    day: simple ? 0 : (startDay = 1),
                    date: nextMonthDate.format('yyyy-MM-dd')
                })
            } else {
                curMonthDate.setDate(startDay)
                nextMonthDate.setDate(startDay)
                week.push({
                    day: startDay,
                    date: isNextMonth ? nextMonthDate.format('yyyy-MM-dd') : curMonthDate.format('yyyy-MM-dd')
                })
            }
        }
        return week
    },
    getCalendar(simple) {
        let minCount = this.startWeekday + this.curMonthCount, // current page mini-days ( this month total days + last month )
            linesCount = Math.floor(minCount / 7) + 1, /// this page total lines at least (if %7==0 => linesTotal + 1 for next month)
            calendar = [] // calendar

        for (let i = 0; i < linesCount; i++) { // one line
            if (i === linesCount - 1) {
                calendar.push(this.setDate(this.curMonthCount).getOneWeek(simple))
            } else {
                calendar.push(this.setDate(1 + i * 7).getOneWeek(simple))
            }
        }
        this.setDate(1)

        return {
            calendar,
            startIndex: this.startWeekday, //firstIndex
            endIndex: minCount % 7 - 1, //lastIndex
        }
    },
    between(t, a, b) { //  a < t < b
        return this.after(t, a) && this.before(t, b)
    },
    equal(d1, d2) {
        return new Date(d1).getTime() == new Date(d2).getTime()
    },
    before(d1, d2) {
        return new Date(d1).getTime() < new Date(d2).getTime()
    },
    after(d1, d2) {
        return new Date(d1).getTime() > new Date(d2).getTime()
    },
    format(f) {
        return this.date.format(f || 'yyyy-MM-dd')
    },
}

export default Calendar
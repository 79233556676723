<template>
  <!-- 登录-我的-校友 -->
  <div class="authen">
    <!-- list start -->
    <div class="au_list">
      <!-- <div v-for="(item,index) in List" :key="index"> -->
      <div class="au_list_uname" v-for="item in activity.activityAppendix" :key="item.activityAppendixId">
        <img
          class="au_wenimg"
          src="@/assets/top_icon/wenjian_icon.png"
          alt=""
        />
        <span class="au_wenspan" v-text="item.fileName"></span>
        <img
          class="au_downimg"
          @click="clickDownload(item)"
          src="@/assets/top_icon/down_icon.png"
          alt=""
        />
      </div>
      <van-empty v-if="!activity.activityAppendix.length" description="暂无资料" />
      <!-- </div> -->
    </div>
    <!-- list end -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activity: {
        activityAppendix: []
      },
    };
  },
  created() {
    this.activity = this.getSession(this.$route.query.sessionKey);
  },
  methods: {
    clickDownload(item) {
      const url1 = this.$store.getters.baseURL + item.fileUrl
      const url2 = `${item.fileUrl}`
      if (item.fileName.includes('.pdf')) {
        window.open(url1)
      } else {
        window.open(url2)
      }
    }
  },
};
</script>

<style scoped>
/* 每个列表中独立样式 */
.au_wenimg {
  position: absolute;
  width: 5rem;
  top: 12%;
  left: 5%;
}
.au_wenspan {
  position: absolute;
  top: 30%;
  left: 20%;
  width: 50%;
  height: 3.6rem;
  font-size: 2rem;
  color: #282828;
}
.au_downimg {
  position: absolute;
  width: 3.2rem;
  top: 27%;
  left: 86%;
}
/* list 列表样式 */
.au_list_btn {
  margin: 2rem 0 0 2rem;
  font-size: 2rem;
  color: #282828;
  background-color: #ffffff;
  border: 0;
}
.au_list_uname {
  position: relative;
  margin-bottom: 0.3rem;
  width: 100%;
  height: 6.6rem;
  background-color: #ffffff;
}

.authen {
  background-color: #f8f9fc;
}
* {
  margin: 0 auto;
}
</style>

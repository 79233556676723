// 封装axios文件，不封装proxy代理不能识别
import axios from 'axios';
import store from '@/store'
import { Toast } from 'vant'
import { clearStorage } from './storage';

const instance = axios.create({
    baseURL: store.getters.baseURL || ''
});

instance.interceptors.request.use(req => {
    req.headers.token = store.getters.token
    return req
})

instance.interceptors.response.use(res => {
    return res
}, err => {
    // console.log(err)
    const { data, status, statusText } = err.response
    if (status === 401) {
        Toast.fail("登录过期")
        clearStorage()
        location.href = '/'
    } else {
        let message = data ? data.msg : statusText || err.message
        if (message == "Network Error") {
            message = "连接异常";
        } else if (message.includes("timeout")) {
            message = "请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "接口" + message.substr(message.length - 3) + "异常";
        }
        Toast.fail(message)
        return Promise.reject(err)
    }
})

export default instance
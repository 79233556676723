<template>
<!-- author:潘浩
    function realization: onScroll触底加载
     time：2022/5/19
 -->
  <div id="con">
      <p>测试页面实现列表数组下拉刷新自动请求添加数组功能</p>
          <!-- <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"> -->
            
            <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
            <div class="container" style=" background-color:#F8F9FC;">
              <div class="articleItem" v-for="(item, index) in list1" :key="index">
                <router-link class="articleJump" :to="`/ongoing`">
                <div class="art_img">
                  <span v-if="value1=='已结束'" class="art_span art_span1">已结束</span>
                  <span v-else-if="value1=='进行中'" class="art_span art_span2">进行中</span>
                  <span v-else-if="value1=='已报满'" class="art_span art_span3">已报满</span>
                </div>
                
                <div>
                  <p>善于学</p>
                  <p style="font-size: 1.2rem">活动时间 {{ item.activityStartTime }}</p>
                <div style="clear:both;"></div>
                </div>   
                </router-link>
              </div>
              <div class="kong"></div>
          </div>
        <!-- </van-list> -->
  </div>
</template>

<script>
export default {
    data () {
        return {
            cnum:0,
            pages:'',
            curpages:'',
            value1:'已报满',
            selected: "activity",
            hname:'',
            list1:[],
            list2:[],
            list: [
                {
                id: 1,
                image: "/article/weixi1.png",
                info: "善于学",
                time: "2021年12月12日 15:00 - 17:00",
                },
                {
                id: 2,
                image: "/article/weixi2.png",
                info: "善于学",
                time: "2021年12月12日 15:00 - 17:00",
                },
                {
                id: 3,
                image: "article/weixi3.png",
                info: "xxx",
                time: "2021年12月12日 15:00 - 17:00",
                },
                {
                id: 3,
                image: "article/weixi3.png",
                info: "xxx",
                time: "2021年12月12日 15:00 - 17:00",
                },
            ],
        }
    },
    created () {
      window.addEventListener('scroll',this.onScroll);//监听scroll事件
    },
    methods: {
      // 滚动触底加载
      onScroll(){//获取内容高度，滚动高度等
        let innerHeight = document.querySelector('#con').clientHeight;
        let scrollTop = document.documentElement.scrollTop;
        let outerHeight = document.documentElement.clientHeight;
         if(innerHeight<=(scrollTop+outerHeight)){
           if(this.pages>this.curpages){
             console.log('加载下一页');
            this.cnum++;
            this.getActivityListPage();
           }else{
             console.log('数据已经到底了');
           }
        }
      },
      waitminus(){//设置定时器，不然innerHeight获取时间比页面渲染时间快，获取到的高度不正确。
        setTimeout(()=>{
          this.onScroll();
        },3000)
      },
      //分页查询活动列表
      getActivityListPage(){
          this.instance.getActivityListPage({
              size:16,
              current:1+this.cnum
          }).then(res=>{
              console.log(res);
              console.log(res.data.data.records);
              this.list2=res.data.data.records;
              this.pages = res.data.data.pages;//总页数
              this.curpages = res.data.data.current;
              this.list1=this.list1.concat(this.list2);//合并对象
              console.log(this.list1);
          })
          
      },
    },
  mounted () {
        this.getActivityListPage(),
        this.waitminus()
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}
.articleItem {
  display: flex;
  flex-direction: row;
  width: 94%;
  height: 26rem;
  bottom: 1rem;
  margin: 2.6rem 0;
  border-radius: 2rem;
  background-color: #ffffff;
}

.kong {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  width: 100%;
  height: 2rem;
}
.articleJump{
  width: 100%;
  text-decoration: none;
}
.art_img{
  display: flex;
  width: 100%;
  height:70%;
  background-image: url('../assets/tweet/weixi1.png');
  border-radius: 0 2rem 0 0;
  background-size: 100%;
}
.art_span1{
  color: #52CC0C;
  background-color: #EAFEDF ;
}
.art_span2{
  color: #FFBD00;
  background-color: #FFF7E1 ;
}
.art_span3{
  color: #FC5531;
  background-color: #FFEEEB ;
}
.art_span{
  width: 16%;
  height: 14%;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  line-height: 2.7rem;
  margin-top: -0.05rem;
  margin-right: -0.011rem;
  border-radius: 0 1rem 0 1rem;
  margin-left: auto;/*子元素在父容器中右对齐*/
}
.articleItem div {
  width: 100%;
  margin-bottom: 1rem;
}

.articleItem div p {
  margin: 0;
  font-size: 2rem;
  margin-left: 1rem;
  margin-top: 1rem;
  color: #1b1b1b;
}
</style>
<template>
  <div class="articleItem pdx pdb">
    <div class="articleItem_tit" v-text="tweet.tweetTitle"></div>
    <div class="articleItem_bri" v-text="`推文简介：${tweet.tweetIntroduction}`"></div>
    <div class="articleItem_timer" v-text="`推文时间：${tweet.tweetCreateTime}`"></div>
    <div class="articleItem_cont1" v-html="tweet.tweetContent"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tweet: {},
    };
  },
  created() {
    this.tweet = this.getSession(this.$route.query.key);
    if (!this.tweet || (this.tweet && this.tweet.tweetTitle !== this.$route.query.tweetTitle)) {
      this.getTweetDetail(this.$route.query.tweetTitle)
    }
  },
  methods: {
    // 根据推文名称获取推文详情
    getTweetDetail(title) {
      console.log(111111)
      this.instance.userTweet({ size: 10, current: 1, parameters: { tweetTitle: title } }).then((res) => {
        const { code, data, msg } = res.data;
        if (code === 1001) {
          this.tweet = data.records[0]
        }
      })
    }
  },
};
</script>

<style scoped>
.articleItem_img {
  widows: 90%;
  height: 18.75rem;
  display: flex;
  justify-content: center;
}

.articleItem_cont1 {
  font-size: 1.75rem;
  color: #333333;
  line-height: 3.4375rem;
  margin-top: 2.9375rem;
  word-wrap:break-word;
}

.articleItem_cont1 img {
  max-width: 100%;
}

.articleItem_timer {
  margin-top: 1.1rem;
  font-size: 1.5rem;
  color: #bfbfbf;
}

.articleItem_bri {
  margin: auto;
  margin-top: 1.3rem;
  font-size: 1.6rem;
  color: #5e5e5e;
  word-wrap:break-word;
}

.articleItem_tit {
  color: #333333;
  font-size: 2.625rem;
  font-weight: bold;
  word-wrap:break-word;
}

.articleItem {
  padding-top: 2.125rem;
}

/* mtheader */
.mtheader {
  width: 100%;
  height: 5.5rem;
  font-size: 2rem;
  background-color: #ffffff;
  color: black;
}

.mtheader img {
  width: 3rem;
  height: 3rem;
}
</style>
import instance from '@/utils/request'

export default {
    updateAdmin(data) {//修改账号信息
        return instance.put('/system/api/v1/account/updateAdmin', data);
    },
    getActivityList(data) {//不分页查询活动列表
        return instance.get("/function/api/v1/activity/getActivityList"+`?parameters=${encodeURIComponent(JSON.stringify(data.parameters))}`, data)
    },
    censusActivityPeopleState(data) {//统计项目报名人员各状态数量
        return instance.get("/function/api/v1/census/censusActivityPeopleState" + `?activityId=${data.activityId}`, data)
    },
    findActivityPeoplePage(data) {//分页查询活动人员列表
        return instance.get('/function/api/v1/people/findActivityPeoplePage' + `/${data.size}/${data.current}` + '?parameters=' + encodeURIComponent(JSON.stringify(data.parameters)), data);
    },
    addTweet(data) {//添加活动人员
        return instance.post('/function/api/v1/people/addTweet', data);
    },
    getActivityListPage(data) {// 分页查询活动列表
        return instance.get('/function/api/v1/activity/getActivityListPage' + `/${data.size}/${data.current}?parameters=${encodeURIComponent(JSON.stringify(data.parameters))}`, data);
    },
    findAdminByAdminId(data) {//查询账号信息
        return instance.get('/system/api/v1/person/findAdminByAdminId?adminId=' + `${data.adminId}`, data);
    },
    rejectExamine(data) { // 驳回审核
        return instance.post('/system/api/v1/examine/rejectExamine', data);
    },
    adoptExamine(data) { //通过审核
        return instance.post('/system/api/v1/examine/adoptExamine', data);
    },
    getActivityDetailById(data) {//根据活动id查询活动详情
        return instance.get('/function/api/v1/activity/getActivityDetailById?activityId=' + `${data.activityId}`, data);
    },
    userTweet(data) {  //推文列表
        return instance.get('/function/api/v1/tweet/findTweetPage' + `/${data.size}/${data.current}` + '?parameters=' + encodeURIComponent(JSON.stringify(data.parameters)), data);
    },
    log(data) {   //登录
        return instance.post('/system/api/v1/login/login', data);
    },
    wxget() {     //微信授权
        return instance.get('/system/api/v1/wx/wxLogin');
    },
    userActive(data) {   //根据活动id查询详情活动详情
        return instance.get('/function/api/v1/activity/getActivityDetailById?' + `activityId=${data.activityId}`, data);
    },
    userexportActivityPeople(data) {  //导出活动人员
        return instance.get('/function/api/v1/people/exportActivityPeople?parameters=' + encodeURIComponent(JSON.stringify(data.parameters)), data
        );
    },
    addAdmin(data) {//注册账号
        return instance.post(`/system/api/v1/account/${data.adminId ? 'addAdmin' : 'updateAdmin'}`, data);
    }
}
//
// [current,size]
// PluginAPI(xxx,xxx,currentparams){
//     if(currentparams!=null){
//         config.url=urrl+'/'
//     }

// }
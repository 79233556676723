import axios from '@/utils/request'

export function loadActivityDetail(params) {
    return axios({
        url: '/function/api/v1/activity/getActivityDetailById',
        method: 'get',
        params
    })
}

export function loadMembers(params) {
    const { parameters, size = 10, current = 1 } = params
    return axios({
        url: `/function/api/v1/people/findActivityPeoplePage/${size}/${current}`,
        method: 'get',
        params
    })
}
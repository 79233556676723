<template>
  <div class="divv">
    <div class="display:flex;">
      <div class="container" @click="$store.dispatch('checkAuth')">
        <div class="head-wrapper">
          <img class="con_img" :src="userInfo.heardUrl" @error="defaultUserInfo.heardUrl" />
        </div>
        <div class="con_btn">
          <div>
            <p v-text="userInfo.fullName || '请登录'"></p>
          </div>
          <div v-if="showAuditStatus">
            <!-- 原来的 -->
            <button class="con_btn_tologo"
              v-text="$store.getters.auditStatus[this.userInfo.auditStatus || '未认证']"></button>
            <!-- 自己写的 -->
            <!-- 重新认证 -->
            <!-- {{ $store.getters.auditStatus[this.userInfo.auditStatus || '未认证'] }} -->
            <button class="con_btn_tologo2"
              v-if="$store.getters.auditStatus[this.userInfo.auditStatus || '未认证'] == '认证失败'" v-text="btnText"></button>
          </div>
        </div>
      </div>
      <div class="mgt">
        <van-cell class="fontSize" title="个人信息" is-link @click="clickPersonalInfo" />
        <van-cell class="fontSize" v-if="userInfo.fullName" title="我的活动" is-link @click="clickActivityInfo" />
      </div>

      <div class="container2" v-if="Boolean(userInfo.adminId)">
        <button class="con_btn_out" @click="logout">退出登录</button>
      </div>
    </div>
    <LFooter />
  </div>
</template>

<script>
import { clearStorage, clearSession } from "@/utils/storage";
export default {
  components: {
    LFooter: () => import("@/components/LFooter.vue"),
  },
  data() {
    const defaultUserInfo = {
      heardUrl: require("@/assets/top_icon/mine_tou.png"),
    };
    return {
      btnText: "重新认证",
      defaultUserInfo,
      userInfo: this.$store.getters.userInfo || defaultUserInfo,
      adminName: "请登录",
      purl: "",
      selected: "mine",
      adminId: "",
      uname: "",
      title: "未认证",
      logurl: "",
      auditStatus: "",
      rourl: "",
      hurl: "",
      adminInfoId: "",
      roleName: null,
    };
  },
  computed: {
    showAuditStatus() {
      const { roles } = this.userInfo
      return !'30 31'.includes(roles && roles[0].roleId)
    }
  },
  mounted() {
    this.$store.dispatch('getUserInfo')
  },
  methods: {
    clickPersonalInfo() {
      console.log(21341231);
      const paths = {
        28: "/mine/alumns",
        29: "/mine/staff",
        30: "/mine/guest",
        31: "/mine/parent",
      },
        roleId = this.userInfo.roles && this.userInfo.roles[0].roleId;
      // 自己写死的id
      // roleId=28
      // this.$router.push('/mine/alumns');
      if (roleId) {
        this.$router.push(paths[roleId]);
      } else {
        this.$router.push("/sign");
      }
    },
    clickActivityInfo() {
      const roleId = this.userInfo.roles && this.userInfo.roles[0].roleId;
      // 自己写死的id
      // roleId=28
      // this.$router.push('/mine/alumns');
      if (roleId) {
        this.$router.push("/mine/activity")
      } else {
        this.$router.push("/sign");
      }

    },
    logout() {
      clearStorage();
      clearSession();
      window.location.href = "/";
    },
  },
};
</script>

<style scoped>
/* 面板 */
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #1988fc;
  margin-top: -0.1rem;
  padding: 1rem;
}

.con_btn {
  display: flex;
  width: 85%;
  flex-direction: column;
}

.head-wrapper {
  width: 7.125rem;
  border-radius: 1rem;
  overflow: hidden;
}

.con_img {
  width: 100%;
}

.con_btn p {
  width: 36rem;
  height: 3rem;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 3rem;
  margin: 1rem 0 0 1rem;
  color: #ffffff;
}

.con_btn_tologo {
  margin: 0.6rem 0 0 1rem;
  padding: 0 1rem;
  line-height: 1.6;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1988fc;
  background-color: #e0e4f8;
  border-radius: 2rem;
  border: 0;
}

.con_btn_tologo2 {
  margin: -10rem 0 0 20rem;
  padding: 0 1rem;
  line-height: 1.6;
  font-size: 1.5rem;
  font-weight: bold;
  color: #1988fc;
  background-color: #e0e4f8;
  border-radius: 2rem;
  border: 0;
}

.con_btn_tologo.fail {
  color: #fc5531;
  background-color: #ffeeeb;
}

/* 个人信息 */
.container1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  height: 10rem;
  margin-top: 3rem;
}

.tiaozhuan {
  font-size: 2rem;
  font-weight: bold;
  color: #333333;
  margin-left: 2rem;
}

.img2 {
  width: 9%;
  margin-right: 1rem;
}

.container2 {
  position: fixed;
  top: 78%;
  width: 100%;
  height: 5.5rem;
}

.con_btn_out {
  position: absolute;
  width: 70%;
  height: 5.5rem;
  left: 15%;
  font-size: 2rem;
  color: #ffffff;
  background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
  border: 0;
  border-radius: 5rem;
}

/* 底部选项卡 */
.tab span {
  position: relative;
  top: 1.375rem;
  left: 0.7rem;
  width: 2.25rem;
  height: 1.5625rem;
  font-size: 1.125rem;
}

.tab a {
  color: #5e5e5e;
}

.tab img {
  width: 3.125rem;
  height: 3.125rem;
}

.tab {
  height: 5.5rem;
  z-index: 3;
  background-color: #ffffff;
}

.tab>>>.mint-tabbar {
  background-size: 100% 0.0625rem;
}

.tab>>>.mint-tab-item.is-selected {
  background-color: #ffffff;
}

/* 导航 */
.mtheader {
  height: 5.5rem;
  background-color: #1988fc;
}

.mtheader>>>.mint-header-title {
  font-size: 2rem;
}

.divv {
  background-color: #f8f9fc;
}

.fontSize{
  font-size: 16px;
  margin-bottom: 5px;
}
</style>
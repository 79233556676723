<template>
  <div class="container flexible">
    <van-list class="pdx pdb" v-model="loading" :finished="finished" finished-text="没有更多了" @load="findActivityPeoplePage">
      <div class="articleItem" v-for="(item, index) in list" :key="index">
        <div @click="clickActivity(item)">
          <div class="rel">
            <div class="rel_img">
              <img src="../../../src/assets/activity_icon/icon@2x.png" v-if="item.acIsCharge === '是'" />
            </div>
            <div class="txt-title">
              {{ item.activityName }}
            </div>
            <div class="tag abs pdx t14" :class="[getStatus(item)]" v-text="status[item.status]"></div>
          </div>
          <div class="txt-dark">
            <div v-text="`活动时间 ${parseTime(item.activityStartTime, '{yyyy}-{mm}-{dd} {hh}:{ii}')}`">
            </div>
          </div>
        </div>
        <div class="pda">
          <button class="btn_no_2"
            v-if="item.isEnter === '否' && item.isDiscard === '否' && (item.acIsCharge === '否' || (item.acIsCharge === '是' && item.isCharge === '是'))"
            round type="info" @click="gotoQrcode(item)">二维码</button>
          <button class="btn_no_1"
            v-if="!item.isTimeout && item.acIsCharge === '否' && item.isEnter === '否' && item.isDiscard === '否'" round
            type="info" @click="applicat(item)">取消报名</button>
        </div>
      </div>
    </van-list>
  </div>
</template>


<script>
import { deleteActivityPeople } from '@/api/user'
export default {
  data() {
    return {
      loading: false,
      finished: false,
      cnum: 0,
      pages: '', //总页数
      curpages: '', //当前页数
      selected: 'activity',
      hname: '',
      list: [],
      list1: [],
      adminId: '',
      auditStatus: '',
      status: { cancel: '已取消', pay: '待缴费', writeOffce: '待核销', writeOffed: "已核销" },
    }
  },
  methods: {
    getStatus(item) {
      //已取消
      if (item.isDiscard === "是") {
        return (item.status = 'cancel')
      }
      //待缴费
      if (item.acIsCharge === "是" && item.isCharge === "否") {
        return (item.status = 'pay')
      }
      //已核销、待核销
      if (item.isEnter === "否") {
        return (item.status = 'writeOffce')
      } else {
        return (item.status = 'writeOffed')
      }
    },
    gotoQrcode(item) {
      const storageKey = 'qrcodeData'
      this.setSession(storageKey, item)
      //已取消跳转
      this.$router.push({
        path: '/mine/qrcode',
        query: { key: storageKey },
      })
    },
    clickActivity(item) {
      const storageKey = 'clickActivity'
      this.setSession(storageKey, item)
      //已取消跳转
      if (item.isDiscard === "是") {
        this.$router.push({
          path: '/mine/cancel',
          query: { key: storageKey },
        })
        return
      }
      //待缴费跳转
      if (item.acIsCharge === "是" && item.isCharge === "否") {
        this.$router.push({
          path: '/mine/pay',
          query: { key: storageKey },
        })
        return
      }
      //已核销跳转
      if (item.isEnter === "是") {
        this.$router.push({
          path: '/mine/writeoffed',
          query: { key: storageKey },
        })
        return
      }

      //待核销跳转
      if (item.isEnter === "否") {
        this.$router.push({
          path: '/mine/writeoffce',
          query: { key: storageKey },
        })
        return
      }

    },
    //分页查询活动列表
    findActivityPeoplePage() {
      this.loading = true
      this.adminId = this.$store.getters.userInfo.adminId;
      // console.log(this.adminId, "this.adminId");
      this.instance
        .findActivityPeoplePage({
          size: 16,
          current: 1 + this.cnum++,
          parameters: { "adminId": this.adminId },
        })
        .then((res) => {
          this.loading = false
          this.finished = res.data.data.current >= res.data.data.pages
          this.list1 = this.handleList(res.data.data.records)
          this.list = this.handleList(this.list.concat(this.list1)) //合并对象
          // console.log(this.list)
        })
    },
    //取消报名
    async applicat(item) {
      await this.$dialog.confirm({ message: "确认取消报名？" });
      console.log(item.activityStartTime, "enroll_end_time");
      if (new Date(item.activityStartTime.replace(/\-/g, '/')).getTime() - (1000 * 60 * 60) < new Date().getTime()) {
        await this.$dialog.confirm({ title: "提示", message: "活动开始前1小时禁止取消报名，请谅解！", confirmButtonText: "已知晓", showCancelButton: false });
        return;
      }
      deleteActivityPeople(item.activityParticipantsId).then(res => {
        console.log(res, "取消报名");
        this.list = []; // 清空list数组
        this.cnum = 0
        this.findActivityPeoplePage()
      })
    },
    // 对分页获取的数据进行处理
    handleList(arr) {
      let resId = {}
      let resArr = []
      for (let i = 0; i < arr.length; i++) {
        if (!resId[arr[i].activityId]) {
          resId[arr[i].activityId] = []
        }
        resId[arr[i].activityId].push(arr[i])
      }
      // console.log(resId)
      for (let key in resId) {
        if (resId[key].length === 0) {
          resArr.push(resId[key][0])
        } else {
          const index = resId[key].findIndex(v => v.isDiscard === "否")
          if (index === -1) {
            resArr.push(resId[key][0])
          } else {
            resArr.push(resId[key][index])
          }
        }
      }
      // console.log(resArr)
      // 对 resArr 进行排序，依据活动时间排序
      resArr.sort((a, b) => {
        return +new Date(b.activityStartTime.replace(/\-/g, '/')) - +new Date(a.activityStartTime.replace(/\-/g, '/'))
      })
      // 判断是否超过活动开始时间
      const now = +new Date()
      resArr.forEach(item => {
        if (+new Date(item.activityStartTime.replace(/\-/g, '/')) > now) {
          item['isTimeout'] = false
        } else {
          item['isTimeout'] = true
        }
      })
      return resArr
    }
  },
  mounted() {
    this.findActivityPeoplePage()
  },
  computed: {
    loginname() {
      //获取从注册页传递过来的数据
      return this.$route.params.hname
    },
  },
}
</script>

<style scoped>
.tab span {
  position: relative;
  display: flex;
  top: 1.375rem;
  left: 0.7rem;
  width: 2.25rem;
  height: 1.5625rem;
  font-size: 1.125rem;
}

.tab a {
  color: #5e5e5e;
}

.tab>>>.mint-tab-item.is-selected {
  background-color: #ffffff;
}

.tab>>>.mint-tabbar {
  background-size: 100% 0.0625rem;
}

.tab img {
  width: 3.125rem;
  height: 3.125rem;
}

.tab {
  height: 5.5rem;
  background-color: #ffffff;
  z-index: 3;
}

/* 上方大空白 */
.container {
  background-color: #f8f9fc;
}

.articleItem {
  position: relative;
  border-radius: 2.1rem;
  overflow: hidden;
  background-color: #ffffff;
}

.articleItem+.articleItem {
  margin-top: 2.1rem;
}

.tag {
  right: 0;
  top: 20px;
  font-size: 2rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-bottom-left-radius: 1.18rem;
}

.tag.finished {
  color: #fc5531;
  background-color: #ffeeeb;
}

.tag.started {
  color: #52cc0c;
  background-color: #eafedf;
}

.tag.full {
  color: #ffbd00;
  background-color: #fff7e1;
}

.art_span {
  width: 16%;
  height: 14%;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  line-height: 2.7rem;
  margin-top: -0.05rem;
  margin-right: -0.011rem;
  border-radius: 0 1rem 0 1rem;
  margin-left: auto;
  /*子元素在父容器中右对齐*/
}

.rut {
  position: absolute;
  top: 53%;
  left: 41%;
}

/* 时间样式 */
.txt-dark {
  font-size: 2rem;
  padding-left: 20px;
  padding-top: 20px;
}

/* 标志样式 */
.rel_img {
  position: absolute;
  padding-top: 20px;
  padding-left: 20px;
  width: 35px;
  height: 40px;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #282828;
  line-height: 0px;

}

/* 标题样式 */
.txt-title {
  width: 75%;
  padding-left: 28px;
  padding-top: 20px;
  font-size: 2rem;
  font-family: PingFang SC;
  line-height: 48px;
  font-weight: bold;
  color: #282828;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.img img {
  width: 100%;
}

.mtbtn {
  width: 9rem;
  height: 3.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.69rem 1.3125rem;
  border-radius: 2rem;
}

.mtbtn a {
  text-decoration: none;
  color: #ffffff;
}

.mtheader img {
  line-height: 0;
}

.mtheader {
  height: 5.6rem;
  font-size: 2rem;
}

.tag.cancel {
  color: #BFBFBF;
}

.tag.pay {
  color: #FC4A1A;
}

.tag.writeOffce {
  color: #1686FC;
}

.tag.writeOffed {
  color: #52CC0C;
}

.pda {
  display: flex;
  justify-content: flex-end;
}


.btn_no_1 {
  width: 9rem;
  height: 4rem;
  font-size: 1.7rem;
  color: #FC4A1A;
  background-color: #ffffff;
  border: 1px solid #FC4A1A;
  border-radius: 4.5625rem;
  margin-left: 20px;
}

.btn_no_2 {
  width: 9rem;
  height: 4rem;
  font-size: 1.7rem;
  color: #1686FC;
  background-color: #ffffff;
  border: 1px solid #1686FC;
  border-radius: 4.5625rem;
}
</style>




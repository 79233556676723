import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// MintUI 插件
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MintUI)

// Vant UI 插件
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

// Element UI 插件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import '@/assets/css/base.css'

import instance_ from './api/index';
Vue.prototype.instance = instance_;

import { parseTime } from '@/utils/utils'
import { getStorage, setStorage, setSession, getSession } from '@/utils/storage'
Vue.prototype.parseTime = parseTime
Vue.prototype.getStorage = getStorage
Vue.prototype.setStorage = setStorage
Vue.prototype.setSession = setSession
Vue.prototype.getSession = getSession

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

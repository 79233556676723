<template>
  <div class="authen h100">
    <div class="au_list">
      <div class="au_list_p1 pdy pdl flex t12">
        <div>
          <img src="@/assets/top_icon/icon_red.png" alt="" />
        </div>
        <div>
          <p>教职员工您好，请完善你的身份信息(必填)</p>
          <p
            v-if="adminInfo.auditStatus == '未通过'"
            class="mgt danger"
            v-text="`驳回理由：${adminInfo.remarks}`"
          ></p>
        </div>
      </div>
       <!-- 切换角色 -->
       <div class="buttonGlup">
        <van-field
            readonly
            clickable
            :value="jo"
            label="切换角色"
            right-icon="arrow"
            placeholder="点击切换角色"
           @click="showTabJ = true "
        />
           <van-popup v-model="showTabJ" round  position="bottom">
            <van-picker
            show-toolbar
           :columns="jokers"
            @confirm="onjoaker"
            @cancel="showTabJ = false"
             />
          </van-popup>
      </div>
      <!-- 切换角色结束 -->
      <van-form @submit="submit" class="mgt">
        <van-field
          v-model="form.fullName"
          name="fullName"
          label="姓名"
          placeholder="请输入"
          maxlength="12"
          :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <van-field
          v-model="form.adminSex"
          name="adminSex"
          label="性别"
          right-icon="arrow"
          placeholder="请选择"
          readonly
          @click="showsex = true"
          :rules="[{ required: true, message: '请选择性别' }]"
        />
        <van-action-sheet
          v-model="showsex"
          :actions="actionssex"
          @select="onSelectsex"
        />

        <van-field
          name="campus"
          v-model="form.campus"
          label="所在校区"
          right-icon="arrow"
          placeholder="请选择"
          readonly
          @click="showsch = true"
          class="mgt-s"
          :rules="[{ required: true, message: '请选择所在校区' }]"
        />
        <van-action-sheet
          v-model="showsch"
          :actions="actionssch"
          @select="onSelectsch"
        />

        <van-field
          name="workStatus"
          v-model="form.workStatus"
          label="工作状态"
          right-icon="arrow"
          placeholder="请选择"
          readonly
          @click="showWorkStatus = true"
          :rules="[{ required: true, message: '请选择工作状态' }]"
        />
        <van-action-sheet
          v-model="showWorkStatus"
          :actions="workStatus"
          @select="selectWorkStatus"
        />

        <div style="margin: 6em 2em 2em">
          <van-button round block type="info" native-type="submit"
            >提交认证</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { saveAccount } from "@/api/user";
import { Toast } from "vant";
export default {
  data() {
    return {
      jo:'教职员工',//角色
      showTabJ:false,//弹窗显示隐藏
      jokers: ['校友', '教职员工', '家长', '嘉宾'],//切换角色名称
      form: {
        campus: "",
        adminSex: '',
        fullName: "",
        workStatus: ''
      },
      adminInfo: {},
      showsex: false,
      showsch: false,
      showWorkStatus: false,
      workStatus: [{ name: '在职' }, { name: '退休' }],
      actionssex: [{ name: "男" }, { name: "女" }],
      actionssch: [{ name: "七中林荫校区" }, { name: "七中高新校区" }],
    };
  },
  created() {
    const { query } = this.$route;
    console.log("query",query);
    if (query.openId) {
      this.adminInfo = {
        ...query,
      };
    } else {
      const { userInfo } = this.$store.getters;
      console.log("userInfo",userInfo);
      if (userInfo) {
        this.adminInfo = { ...userInfo };
      } else {
        this.$router.back();
      }
    }
  },
  methods: {
    // 切换角色的方法
    onjoaker(value){
      if(value=="教职员工"){
        this.$router.replace('/sign/authenStaff');
      }
      if(value=="嘉宾"){
        this.$router.replace('/sign/authenGuest');
      }
      if(value=="家长"){
        this.$router.replace('/sign/authenMom');
      }
      if(value=="校友"){
        this.$router.replace('/sign/authenSch');
      }
      // 选中的值
      this.jo=value
      // 弹窗关闭
      this.showTabJ = false
    },
    selectWorkStatus(item) {
        this.form.workStatus = item.name
        this.showWorkStatus = false
    },
    onSelectsex(item) {
      this.form.adminSex = item.name;
      this.showsex = false;
    },
    onSelectsch(item) {
      this.form.campus = item.name;
      this.showsch = false;
    },
    async submit() {
      const { form, adminInfo } = this;
      adminInfo.auditStatus='待审核'
      const params = {
        roleIds: [29],
        adminId: adminInfo.adminId,
        adminName: adminInfo.selfPhone,
        adminInfo: Object.assign(adminInfo, form)
      };
      console.log("params",params);
      // 注册教职工接口
      const res = await saveAccount(params);
      const { code, msg } = res.data;
      if (code === 1001) {
        await this.$dialog.alert({
          title: "提示",
          message: "你已经注册成功\n“教职员工”账号需要后台审核,请耐心等待",
          cancelButtonText: "已知晓",
          confirmButtonColor: "#1686fc",
        });

        this.$router.push("/sign");
      } else {
        this.$toast(msg);
      }
    },
  },
};
</script>

<style scoped>
.authen {
  background-color: #f8f9fc;
}

.au_list_p1 {
  background-color: #ffffff;
  line-height: 1;
}

.au_list_p1 img {
  width: 1em;
  margin-right: 0.4em;
}

.au_list_p1 .danger {
  color: #fc4a1a;
}
</style>
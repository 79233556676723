<template>
  <div id="content">
    <!-- 背景-表头 -->
    <div class="reg_img">
      <img class="regg_img" src="../../assets/sign/icon2.png" alt="" />
      <p class="regg_p">林荫街龙门阵</p>
    </div>
    <!-- 账号列表 -->
    <div class="list1">
      <van-row>
        <van-col span="2"></van-col>
        <van-col span="6" class="spn1">选择角色：</van-col>
        <van-col span="16" class="van-c1">
          <van-cell is-link :title="`${aname}`" @click="show = true" />
          <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
          <img class="img2" src="../../assets/reg_image/right_icon.png" alt="" />
        </van-col>
      </van-row>
    </div>

    <div class="flex justify-center">
      <p class="p1">注意：账号注册后，不能再更改账号角色</p>
    </div>

    <div class="list2">
      <van-row>
        <van-col span="2"></van-col>
        <van-col span="6" class="spn1">绑定电话：</van-col>
        <van-col span="12" class="margin-top: 1rem;">
          <input class="inp1" type="text" placeholder="请输入手机号码" maxlength="11" v-model="num" :state="numState"
            @blur="checkblur" />
        </van-col>
      </van-row>
    </div>

    <div class="flex-col align-center">
      <p class="inp_pp"></p>
      <button class="btn1" @click="Jump">下一步</button>
    </div>

    <div class="list4">
      <table>
        <tr>
          <td>角色说明：</td>
        </tr>
        <tr>
          <td>1、校友：为目前在校学生或已毕业的校友们</td>
        </tr>
        <tr>
          <td>2、教职员工：为在职或离休的学校老师员工们</td>
        </tr>
        <tr>
          <td>3、嘉宾：邀请参与的非本校的嘉宾朋友们</td>
        </tr>
        <tr>
          <td>4、学生家长：孩子就读于七中的家长朋友们请您按您真实</td>
        </tr>
      </table>
      <div class="div_end">
        情况选择对应角色,一个账号对应一个角色:注册选择角色时确认无误后点击注册按钮
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: '',
      numState: '',
      aname: '选择身份',
      url1: '',
      show: false,
      actions: [
        { name: '校友' },
        { name: '教职员工' },
        { name: '学生家长' },
        { name: '嘉宾' },
      ],
    }
  },
  methods: {
    checkblur() {
      //inp框失去焦點
      let inp1 = document.getElementsByClassName('inp_pp')
      if (this.checknum() == true) {
        console.log('格式正确')
        inp1[0].innerHTML = ''
        return true
      } else {
        console.log('格式错误')
        inp1[0].innerHTML = '请重新输入手机号'
        console.log(inp1[0].innerHTML)
        return false
      }
    },
    checknum() {
      let reg = /^(13|14|15|18|17)\d{9}$/
      if (reg.test(this.num)) {
        console.log(this.num)
        this.numState = 'success'
        return true
      } else {
        this.numState = 'false'
        return false
      }
    },
    onSelect(item) {
      this.aname = item.name
      this.show = false
    },
    //跳转页面，并且把数据存入sessage中
    Jump(sname, url) {
      if (!this.checkblur()) {
        return
      }
      sname = this.aname
      if (sname == '校友') {
        url = '/sign/authenSch'
      } else if (sname == '教职员工') {
        url = '/sign/authenStaff'
      } else if (sname == '学生家长') {
        url = '/sign/authenMom'
      } else if (sname == '嘉宾') {
        url = '/sign/authenGuest'
      }
      // 这里走的是微信登录的接口的页面
      this.$router.push({
        path: url,
        query: Object.assign(
          { roleName: this.aname, selfPhone: this.num },
          this.wxInfo
        ),
      })
      // 自己写的进入
      // this.$router.push('/sign/authenSch')
    },
  },
  mounted() {
    const {
      openid: openId,
      unionid: unionId = 'unionid',
      headimgurl: heardUrl,
    } = JSON.parse(this.$route.query.info)
    this.wxInfo = { openId, unionId, heardUrl }
  },
}
</script>

<style scoped>
/* 动作面板 */
.van-cell {
  width: 82%;
  height: 6rem;
  display: flex;
  font-size: 2rem;
  flex-direction: row;
  align-items: center;
  background-color: #f8f9fc;
}

.van-c1 {
  display: flex;
  flex-direction: row;
}

.van-icon-arrow:before {
  content: none;
}

.van-action-sheet__item {
  height: 6rem;
  font-size: 2rem;
}

.img2 {
  width: 11%;
  height: 14%;
  margin-top: 1.5rem;
}

/* 账号列表 */
.div_end {
  line-height: 1.6;
}

.list4 td {
  line-height: 1.6;
}

.list4 {
  width: 85%;
  height: 22rem;
  font-size: 1.5rem;
  color: #666666;
}

.p1 {
  margin: 1rem 0 1rem 13rem;
  font-size: 1.235rem;
  color: #fc4a1a;
}

.inp_pp {
  text-align: right;
  width: 50%;
  margin: 1rem 0 0 15rem;
  font-size: 1.235rem;
  color: #fc4a1a;
}

.img1 {
  margin: 1.3rem 0 0 1.6rem;
  height: 50%;
  width: 50%;
}

.inp1::placeholder {
  color: #bfbfbf;
}

.inp1 {
  width: 100%;
  height: 3.75rem;
  margin-top: 5%;
  font-size: 2rem;
  border: 0;
  background-color: #f4f8fe;
  outline: none;
  cursor: pointer;
}

.spn1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 6rem;
}

.btn1 {
  width: 87%;
  font-size: 38px;
  font-weight: 500;
  color: #1686fc;
  border: 0;
  background: #f4f8fe;
}

.btn1:hover {
  color: #ffffff;
  background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
}

.rou {
  width: 87%;
  margin-top: 2rem;
}

.list1,
.list2 {
  margin: 0 auto;
  margin-top: 1.4rem;
  background: #f4f8fe;
  border-radius: 2.8125rem;
}

.btn1 {
  border-radius: 2.8125rem;
  height: 5.75rem;
  font-size: 2rem;
  margin-top: 2em;
  margin-bottom: 0.6em;
}

.list1,
.list2 {
  width: 87%;
}

/* 背景-表头 */
.regg_img {
  width: 6.25rem;
  height: 6.25rem;
  margin-top: 3rem;
}

.regg_p {
  margin-top: 1.6rem;
  font-size: 2.4rem;
  width: 45%;
  height: 16%;
  color: #ffffff;
  text-align: center;
  font-family: Adobe Heiti Std, Adobe Heiti Std;
}

.reg_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*交叉轴居中*/
  width: 100%;
  height: 32%;
  background-image: url('../../assets/reg_image/register_img.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* 面板 */
#content {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}

#content>* {
  margin-left: auto;
  margin-right: auto;
}
</style>

<template>
  <!-- 自定义 ActionSheet 动作面板-->
  <div class="div">
    <van-cell is-link :title="`${aname}`" @click="show = true" />
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
     <img class="img2" src="../assets/reg_image/right_icon.png" alt=""  />
  </div>
</template>

<script>
export default {
  data () {
    return {  
      aname:'选择身份',
      show:false,
      actions:[
        {name:'校友'},
        {name:'教职员工'},
        {name:'学生家长'},
        {name:'嘉宾'}
      ]
    }
  },
  methods: {
    onSelect(item){
      this.aname=item.name;
      console.log(item);
      this.show = false;
    }
  }
}
</script>

<style scoped>
.van-cell{
  width: 60%;
  font-size: 2rem;
  flex-direction: row;
  align-items: center;
}
.van-icon-arrow:before{content: none;}/*去掉自定义箭头图标*/
.van-action-sheet__item{
  height: 6rem;
  font-size: 2rem;
}
.img2{
    width: 7%;
    height: 52%;
    margin-top: 1.5rem;
}
.div{
  display: flex;
  width: 100%;
  height: 6rem;
}
.divv{
  width: 100%;
}
</style>
import axios from '@/utils/request'

export function saveAccount(data) {
    return axios({
        url: `/system/api/v1/account/${data.adminInfo.adminId ? 'updateAdmin' : 'addAdmin'}`,
        method: `${data.adminInfo.adminId ? 'put' : 'post'}`,
        data
    })
}

//微信登录后调取
export function getUserInfo(params) {
    return axios({
        url: '/system/api/v1/account/loginById',
        method: 'get',
        params
    })
}

export function getAccountInfo(params) {
    return axios({
        url: '/system/api/v1/person/findAdminByAdminName',
        method: 'get',
        params
    })
}

export function wxLogin() {
    window.location.href = `${axios.defaults.baseURL}/system/api/v1/wx/wxLogin`
}

//删除活动人员
export function deleteActivityPeople(data){
    return axios({
        url:'/function/api/v1/people/deleteActivityPeople',
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        method:"delete",
        data
    })

}

//确认入场
export function confirmEnter(data){
    return axios({
        url:'/function/api/v1/people/confirmEnter',
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        method:"put",
        data
    })

}

export function hxLogin(params){
    return axios({
        url:"/system/api/v1/login/login",
        method:"POST",
        data:params
    })

}
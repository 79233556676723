<template>
  <div class="authen h100">
    <div class="au_list">
      <div class="au_list_p1 pdy pdl flex t12">
        <div>
          <img src="@/assets/top_icon/icon_red.png" alt="" />
        </div>
        <div>
          <p>嘉宾您好，请完善你的身份信息(必填)</p>
          <p
            v-if="adminInfo.auditStatus == '未通过'"
            class="mgt danger"
            v-text="`驳回理由：${adminInfo.remarks}`"
          ></p>
        </div>
      </div>
       <!-- 切换角色 -->
       <div class="buttonGlup">
        <van-field
            readonly
            clickable
            :value="jo"
            label="切换角色"
            right-icon="arrow"
            placeholder="点击切换角色"
           @click="showTabJ = true "
        />
           <van-popup v-model="showTabJ" round  position="bottom">
            <van-picker
            show-toolbar
           :columns="jokers"
            @confirm="onjoaker"
            @cancel="showTabJ = false"
             />
          </van-popup>
      </div>
      <!-- 切换角色结束 -->
      <van-form @submit="submit" class="mgt">
        <van-field
          v-model="form.fullName"
          name="fullName"
          label="姓名"
          placeholder="请输入"
          maxlength="12"
          :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <van-field
          v-model="form.adminSex"
          name="adminSex"
          label="性别"
          right-icon="arrow"
          placeholder="请选择"
          readonly
          @click="showsex = true"
          :rules="[{ required: true, message: '请选择性别' }]"
        />
        <van-action-sheet
          v-model="showsex"
          :actions="actionssex"
          @select="onSelectsex"
        />
        
        <div style="margin: 6em 2em 2em">
          <van-button round block type="info" native-type="submit"
            >注册</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { saveAccount } from '@/api/user'
import { Toast } from 'vant'
export default {
  data() {
    return {
      jo:'嘉宾',//角色
      showTabJ:false,//弹窗显示隐藏
      jokers: ['校友', '教职员工', '家长', '嘉宾'],//切换角色名称
      form: {
        adminSex: undefined,
        fullName: "",
        auditStatus: '已通过'
      },
      adminInfo: {
      },
      showsex: false,
      actionssex: [{ name: "男" }, { name: "女" }],
    };
  },
  created() {
    const { query } = this.$route;
    if (query.openId) {
      this.adminInfo = {
        ...query,
      };
    } else {
      const { userInfo } = this.$store.getters;
      if (userInfo) {
        this.adminInfo = { ...userInfo };
      } else {
        this.$router.back();
      }
    }
  },
  methods: {
     // 切换角色的方法
    onjoaker(value){
      if(value=="教职员工"){
        this.$router.replace('/sign/authenStaff');
      }
      if(value=="嘉宾"){
        this.$router.replace('/sign/authenGuest');
      }
      if(value=="家长"){
        this.$router.replace('/sign/authenMom');
      }
      if(value=="校友"){
        this.$router.replace('/sign/authenSch');
      }
      // 选中的值
      this.jo=value
      // 弹窗关闭
      this.showTabJ = false
    },
    onSelectsex(item) {
      this.form.adminSex = item.name;
      this.showsex = false;
    },
    async submit() {
      const { form, adminInfo } = this;
      const params = {
        adminId: adminInfo.adminId,
        adminInfo: Object.assign(adminInfo, form),
        adminName: adminInfo.selfPhone,
        roleIds: [30],
        // auditStatus: '已通过'
      };

      const res = await saveAccount(params);
      const { code, msg } = res.data;
      if (code === 1001) {
        Toast({
          type: "success",
          message: "注册成功",
          onClose: () => {
            this.$router.push("/sign");
          },
        });
      } else {
        this.$toast(msg);
      }
    },
  },
};
</script>

<style scoped>
.authen {
  background-color: #f8f9fc;
}

.au_list_p1 {
  background-color: #ffffff;
  line-height: 1;
}

.au_list_p1 img {
  width: 1em;
  margin-right: 0.4em;
}

.au_list_p1 .danger {
  color: #fc4a1a;
}
</style>
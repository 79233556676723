export function setStorage(key, data) {
    const type = typeof data
    data = {
        type,
        data
    }
    localStorage.setItem(key, JSON.stringify(data))
}

export function getStorage(key) {
    let data = localStorage.getItem(key)
    if (data) {
        data = JSON.parse(data)
        return data.data
    }
    return undefined
}

export function clearStorage(key) {
    localStorage[key ? 'removeItem' : 'clear'](key)
}

export function getSession(key) {
    let data = sessionStorage.getItem(key)
    if (data) {
        data = JSON.parse(data)
        return data.data
    }

    return undefined
}
export function setSession(key, data) {
    const type = typeof data
    data = {
        type,
        data
    }
    sessionStorage.setItem(key, JSON.stringify(data))
}

export function clearSession(key) {
    sessionStorage[key ? 'removeItem' : 'clear'](key)
}
<template>
    <div>
        <div class="articleItem">
            <div class="articleItem_tit" v-text="`欢迎参加${activity.activityName}`"></div>
            <div class="art_time" v-text="`活动时间：${parseTime(activity.activityStartTime, '{yyyy}-{mm}-{dd} {hh}:{ii}')}`">
            </div>
            <div class="articleItem_tst">
                <div>请凭借二维码参加活动，请注意保存</div>
            </div>
            <!-- <div v-if=""></div> -->
            <div class="articleItem_time">
                <div v-text="`北京时间：${parseTime(new Date(), '{yyyy}:{mm}:{dd} {hh}:{ii}')}`"></div>
            </div>
            <div class="articleItem_qr">
                <vue-qr :text="qrText" :callback="test" qid="testid"></vue-qr>
            </div>
            <div class="articleItem_num" v-text="`${this.userNum}` + '人'"></div>
        </div>
        <div class="footer">
            <span class="fot3">退费请微信联系工作人员</span>
        </div>
        <el-dialog :visible.sync="dialogVisible" :show-close="false" width="70%"
            style="position: absolute;top: 50%;transform: translateY(-50%);text-align: center;">
            <div
                style="position: absolute;top: 50%;left:50%;transform: translate(-50%,-80%);font-size: 40px;color: #52CC0C">
                <i class="el-icon-circle-check"></i>
            </div>
            <span>扫码成功，已核销{{ this.userNum }}人</span>
        </el-dialog>
    </div>
</template>
<script>
import { Toast } from "vant";
import { loadMembers, loadActivityDetail } from "@/api/activity";
import VueQr from 'vue-qr'
export default {
    components: { VueQr },
    data() {
        return {

            list: [],
            records: [], //查询到的该活动人员
            urlTo: "",
            showp: false,
            num: null, //参会人数
            count: 0, //参与人数
            participantNum: "",
            nowtimer: "",
            activityId: "",
            qrText: "",
            activity: {},
            userNum: "",
            baochi: "",
            prompt: "",//下方提示语
            addTweetCode: "", //判断是否报名成功
            roleName: "", //角色
            timerTF: "", //判断未登录时，活动是否结束
            timerTFend: "", //判断登录后，活动是否结束
            adminId: "",
            auditStatus: this.$store.getters.userInfo?.auditStatus,
            actDone: "", //sessStorage中时间判断按钮显示
            hasJoined: false,
            timer: 0,
            userId: '',
            dialogVisible: false
        };
    },
    computed: {
        showBtnComputed() {

            // 是否显示按钮
            if (this.activityStatusComputed === 2) {
                // 如果是认证中和认证失败不显示
                if (this.auditStatus == "待审核" || this.auditStatus == "未通过") {
                    return false
                }
                return this.isAllowedRoleComputed;
            }
            return true;
        },
        isAllowedRoleComputed() {
            // 是否允许下载资料
            const userInfo = this.$store.getters.userInfo;
            return userInfo ? "28 29".includes(userInfo.roles[0].roleId) : false;
        },
        activityStatusComputed() {
            let { activityStartTime, participantNum, participantsNumber } =
                this.activity;
            activityStartTime = new Date(activityStartTime.replace(/-/g, "/"));
            activityStartTime.setDate(activityStartTime.getDate() - 1);
            if (new Date() < activityStartTime) {
                if (this.hasJoined) {
                    return 3;
                }
                if (participantNum >= participantsNumber) {
                    return 1;
                }
                return 0;
            } else {
                return 2;
            }
        },
        allowRolesComputed() {
            const { participantsRoles } = this.activity;
            return participantsRoles.map((role) => role.roleName).join("、");
        },
    },
    created() {
        const { key } = this.$route.query;
        this.activity = this.getSession(key);
        console.log(this.activity.adminId)
        this.userId = this.activity.adminId
        this.userNum = this.activity.userNum
        const parm = {
            "activityParticipantsId": this.activity.activityParticipantsId,
            "adminId": this.activity.adminId,
            "activityId": this.activity.activityId,
            "isCharge": this.activity.isCharge,
            "isRefund": this.activity.isRefund,
            "isEnter": this.activity.isEnter,
            "isDiscard": this.activity.isDiscard,
            "userNum": this.activity.userNum,
            "activityName": this.activity.activityName,
            "activityStartTime": this.activity.activityStartTime,
            "enrollEndTime": this.activity.enrollEndTime,
            "acIsCharge": this.activity.acIsCharge,
        }
        this.qrText = JSON.stringify(parm)
        if (this.activity.isDiscard === "是") {
            this.prompt = "报名已取消"
        }
        this.loadActivityDetail()
        this.getStatus(this.activity)
        if (this.$store.getters.userInfo) {
            this.loadMembers();
        }
    },
    destroyed() {
        clearInterval(this.timer)
    },
    methods: {
        test(data) {
            // console.log(data);
        },
        // 获取用户该活动的状态
        getStatus(item) {
            // console.log(item)
            clearInterval(this.timer)
            this.instance
                .findActivityPeoplePage({
                    size: 16,
                    current: 1,
                    parameters: { "adminId": this.userId, "activityId": item.activityId },
                }).then(async res => {
                    // console.log(res)
                    const index = res.data.data.records.findIndex(v => v.isEnter === '是')
                    if (index !== -1) {
                        clearInterval(this.timer)
                        this.dialogVisible = true
                        setTimeout(() => {
                            this.$router.push("/mine/activity")
                        }, 3000)
                    } else {
                        this.timer = window.setInterval(() => {
                            this.getStatus(this.activity);
                        }, 100)
                    }
                })
        },
        async loadActivityDetail() {
            const { activityId } = this.activity;
            const { data } = await loadActivityDetail({ activityId });
            if (data.code === 1001) {
                this.activity = data.data;
                this.setSession(this.$route.query.key, data.data);
            } else {
                this.$toast(data.msg);
            }
            console.log(this.activity)
        },
        async loadMembers() {
            const res = await loadMembers({
                parameters: {
                    activityId: this.activity.activityId,
                    adminId: this.$store.getters.userInfo.adminId,
                },
            });
            const { msg, code, data } = res.data;
            if (code === 1001) {
                this.hasJoined = data.records.length > 0;
            }
        },
        async clickStatusBtn() {
            const { userInfo } = this.$store.getters;
            if (userInfo) {
                if (this.activityStatusComputed === 0) {
                    const allowed = await this.$store.dispatch("checkAuth"); // 账号认证情况判断
                    if (!allowed) return;

                    const { participantsRoles } = this.activity,
                        roleId = userInfo.roles[0].roleId;
                    // 允许的角色判断
                    if (!participantsRoles.some((role) => role.roleId == roleId)) {
                        return Toast({
                            type: "fail",
                            message: "您不在范围内",
                        });
                    }
                    await this.$dialog.confirm({ title: "报名", message: "确认报名？" });
                    const params = {
                        activityId: this.activity.activityId,
                        adminId: userInfo.adminId,
                    };
                    console.log('测试11111111111111');
                    const res = await this.instance.addTweet(params);
                    console.log("resresresresresres", res);
                    const { code, data, msg } = res.data;
                    if (code === 1001) {
                        Toast({
                            type: "success",
                            message: "报名成功",
                        });
                        this.loadMembers();
                    } else {
                        this.$toast(msg);
                    }
                    return;
                }

                if (this.activityStatusComputed === 2 && this.isAllowedRoleComputed) { // 活动下载资料
                    return this.$router.push({
                        path: "/ongoing/download",
                        query: { sessionKey: this.$route.query.key },
                    });
                }

                if (this.hasJoined) { // 已经报名
                    return;
                }
            } else if (this.activityStatusComputed === 0) {
                this.$router.push("/sign");
            }
        },
        // 点击按钮添加活动人员
        addTweet() {
            this.adminId = sessionStorage.getItem("adminId");
            console.log(this.adminId);
            this.instance
                .addTweet({
                    activityId: this.activity.activityId,
                    activityParticipantsId: 1,
                    adminId: this.adminId,
                })
                .then((res) => {
                    this.addTweetCode = res.data.code;
                    if (res.data.code == 1001) {
                        console.log("添加活动人员-suc", res);
                    } else if (res.data.code == 1000) {
                        console.log("该角色不能参加该活动");
                    } else {
                        console.log("再次点击");
                    }
                })
                .catch((err) => {
                    console.log("添加活动人员-err", err);
                });
        },
        //分页查询该活动人员列表
        findActivityPeoplePage() {
            this.instance
                .findActivityPeoplePage({
                    current: 1,
                    parameters: { activityId: this.activity.activityId },
                    size: 40,
                })
                .then((res) => {
                    console.log("分页查询该活动人员列表", res);
                    this.records = res.data.data.records;
                    console.log(this.records);
                    if (res.data.code == 1001) {
                        console.log("处理成功");
                        this.findPeople();
                    } else if (res.data.code == 1000) {
                        console.log("该角色不能参加该活动");
                    }
                })
                .catch((err) => {
                    console.log("分页查询该活动人员列表", err);
                });
        },
        // 遍历活动人员列表查询是有存在已报名账号
        findPeople() {
            this.adminId = sessionStorage.getItem("adminId");
            for (let i = 0; i < this.records.length; i++) {
                let arr = [];
                arr = this.records[i];
                if (this.adminId == arr.adminId) {
                    //若在遍历后adminId与登录后用户adminId进行对比
                    this.baochi = 1;
                } else {
                    this.baochi = 0;
                }
            }
        },
    },
};
</script>
  
<style scoped>
/* 弹窗样式 */
.router_btn {
    width: 6em;
}

.btn_no {
    width: 100%;
    height: 4rem;
    font-size: 1.5rem;
    border: 0;
    border-radius: 4.5625rem;
}

.btn_logo {
    width: 30%;
    height: 4rem;
    font-size: 1.5rem;
    border: 0;
    border-radius: 4.5625rem;
}

/* 已登录 */
.btnlogo_start {
    margin-left: 10rem;
    color: #ffffff;
    background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
}

/* 未登录 */
.btnnologo_start {
    /**未登录 报名中 */
    color: #ffffff;
    background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
}

.pnologo_start {
    color: #5e5e5e;
    left: calc(100% + 2em);
    bottom: 0;
    white-space: nowrap;
}

.plogo_start {
    color: #5e5e5e;
    margin-left: 2rem !important;
}

.pnologo_done {
    margin-left: 11rem !important;
}

.btnnologo_done {
    /* color: #ffffff; */
    color: #5e5e5e;
    background-color: #d5d5d5;
    /* background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%); */
}

.btnnologo_end {
    color: #5e5e5e;
    background-color: #d5d5d5;
}

.art_d {
    width: 29%;
    height: 4rem;
    color: #5e5e5e;
    font-size: 1.5rem;
    background: #d5d5d5;
    border: 0;
    border-radius: 4.5625rem;
}

.art_b {
    width: 29%;
    height: 4rem;
    margin-left: 11rem;
    color: #ffffff;
    font-size: 1.5rem;
    background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
    border: 0;
    border-radius: 4.5625rem;
}

.pop_img {
    margin-top: 5rem;
}

.au_list_popup {
    width: 60%;
    height: 22%;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #333333;
    border-radius: 2rem;
}

.mint-msgbox {
    height: 24rem !important;
}

.mint-msgbox-title {
    font-size: 2rem;
}

/* footer */
.footer p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 3rem;
    color: #1686fc;

}

/* ******************************************* */
.download {
    width: 11rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.5rem;
    color: #ffffff;
    background-color: #fdc830;
    border-radius: 2rem;
}

/* ******************************************** */
.fot3 {
    width: 100%;
    height: 2rem;
    font-size: 1.5rem;
    color: #fc4a1a;
    margin-top: 0.1rem;
}

.fot2 {
    width: 100%;
    height: 2rem;
    font-size: 1.5rem;
    color: #fc4a1a;
    margin-top: 2rem;
}

.fot4 {
    margin-left: 6%;
    width: 29%;
    height: 4rem;
    font-size: 1.5rem;
    font-weight: bold;
    padding-left: 0;
    padding: 0.875rem 3.625rem;
    border-radius: 2.125rem;
    color: #5e5e5e;
    border: 0;
    background-color: #d5d5d5;
}

.fot1 {
    margin-left: 36%;
    width: 14%;
    height: 2.5rem;
    font-size: 1.75rem;
    font-weight: bold;
    padding: 0.875rem 3.625rem;
    border-radius: 2.125rem;
    color: #ffffff;
    background-color: #1686fc;
    box-shadow: 0 0.375rem 1.875rem rgba(22, 134, 252, 0.37);
}

.footer_clo {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer {
    position: absolute;
    /* 绝对定位 */
    bottom: 50px;
    /* 距离底部50px */
    left: 0;
    /* 水平方向靠左对齐 */
    width: 100%;
    /* 宽度占满父容器 */
    height: 30px;
    /* 高度为30px，根据实际需求调整 */
    /* 背景色，根据实际需求调整 */
    /* border-top: 1px solid #d9d9d9; */
    /* 顶部边框，根据实际需求调整 */
    text-align: center;
    /* 文字居中对齐 */
    line-height: 30px;
    /* 行高等于高度，让文字垂直居中 */
}

/* 文章詳情 */
.articleItem img {
    width: 90%;
    margin: 0 auto;
    display: block;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    /* 水平居中 */
}

.articleItem_cont1 {
    width: 90%;
    font-size: 1.75rem;
    color: #333333;
    line-height: 3.4375rem;
    margin: 0 auto;
}

.articleItem_cont1 img {
    max-width: 100%;
}

.articleItem_timer {
    width: 90%;
    margin: 0 auto;
    padding-top: 1px;
    font-size: 1.75rem;
    color: #bfbfbf;
    text-align: center;
    /* 文本水平居中 */
}

.articleItem_tst {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: 20px;
    padding-top: 1px;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
    color: #1686FC;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    border: 1px solid #fff;
    background: rgb(232, 243, 254);
    border-radius: 5px;
}

.articleItem_bri {
    width: 90%;
    margin: 0 auto;
    padding-top: 1rem;
    font-size: 1.75rem;
    color: #5e5e5e;
}

.articleItem_tit {
    width: 90%;
    color: #333333;
    font-size: 2.625rem;
    font-weight: bold;
    margin: 0 auto;
    padding-top: 2.125rem;
    text-align: center;
    /* 文本水平居中 */
}

.articleItem_qr {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: center;
    /* 水平居中 */

}

.articleItem_num {
    text-align: center;
}

/* mtheader */
.mtheader {
    width: 100%;
    height: 5.5rem;
    font-size: 2rem;
    background-color: #ffffff;
    color: black;
}

.art_time {
    width: 90%;
    margin: 0 auto;
    padding-top: 1px;
    text-align: left;
    color: #BFBFBF;
    font-size: 1.8rem;

}

.mtheader img {
    width: 3rem;
    height: 3rem;
}

.articleItem_time {
    font-size: 15px;
    font-family: PingFang SC, PingFang SC;
    text-align: center;
    font-weight: 400;
    color: #FC4A1A;
    line-height: 0px;
}
</style>
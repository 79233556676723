<template>
    <div class="container">
        <van-list v-model="loading" :finished="finished" @load="findActivityPeoplePage">
            <!-- <div class="record">已核销记录</div> -->
            <div class="articleItem" v-for="(item, index) in list" :key="index">
                <div class="rel">
                    <div class="rel_fullName" v-text="item.findPersonModel.fullName"></div>
                    <div class="rel_selfPhone" v-text="item.findPersonModel.selfPhone"></div>
                    <div class="rel_userNum" v-text="`${item.userNum}人`"></div>
                </div>
            </div>
        </van-list>
    </div>
</template>
  
  
<script>
export default {
    data() {
        return {
            loading: false,
            finished: false,
            cnum: 0,
            pages: '', //总页数
            curpages: '', //当前页数
            hname: '',
            list: [],
            list1: [],
            activityId: '',
        }
    },
    methods: {
        //分页查询活动列表
        findActivityPeoplePage() {
            this.loading = true
            const { key } = this.$route.query;
            this.activityId = this.getSession(key).activityId;
            console.log(this.activityId, "this.activityId");
            this.instance
                .findActivityPeoplePage({
                    size: 16,
                    current: 1 + this.cnum++,
                    parameters: { "activityId": this.activityId, "isEnter": "是" },
                })
                .then((res) => {
                    console.log(res, "res");
                    this.loading = false
                    this.finished = res.data.data.current >= res.data.data.pages
                    this.list1 = res.data.data.records
                    this.list = this.list.concat(this.list1) //合并对象
                })
        },
    },
    mounted() {
        this.findActivityPeoplePage()
    },
    computed: {
        loginname() {
            //获取从注册页传递过来的数据
            return this.$route.params.hname
        },
    },
}
</script>
  
<style scoped>
.tab span {
    position: relative;
    display: flex;
    top: 1.375rem;
    left: 0.7rem;
    width: 2.25rem;
    height: 1.5625rem;
    font-size: 1.125rem;
}

.tab a {
    color: #5e5e5e;
}

.tab>>>.mint-tab-item.is-selected {
    background-color: #ffffff;
}

.tab>>>.mint-tabbar {
    background-size: 100% 0.0625rem;
}

.tab img {
    width: 3.125rem;
    height: 3.125rem;
}

.tab {
    height: 5.5rem;
    background-color: #ffffff;
    z-index: 3;
}

.container {
    width: 100vw;
    height: 100vh;
    padding-top: 2.4rem;
    background-color: #EFF1F3;
}

.articleItem {
    margin: 0 10px;
    border-radius: 0.9rem;
    overflow: hidden;
    background-color: #ffffff;
}

.articleItem+.articleItem {
    margin-top: .8rem;
}

.tag {
    right: 0;
    top: 20px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border-bottom-left-radius: 1.18rem;
}

.tag.finished {
    color: #fc5531;
    background-color: #ffeeeb;
}

.tag.started {
    color: #52cc0c;
    background-color: #eafedf;
}

.tag.full {
    color: #ffbd00;
    background-color: #fff7e1;
}

.art_span {
    width: 16%;
    height: 14%;
    font-size: 1.6rem;
    text-align: center;
    font-weight: bold;
    line-height: 2.7rem;
    margin-top: -0.05rem;
    margin-right: -0.011rem;
    border-radius: 0 1rem 0 1rem;
    margin-left: auto;
    /*子元素在父容器中右对齐*/
}

.rut {
    position: absolute;
    top: 53%;
    left: 41%;
}

/* 时间样式 */
.txt-dark {
    padding-left: 20px;
    padding-top: 20px;
}

/* 标志样式 */
.rel_img {
    position: absolute;
    padding-top: 20px;
    padding-left: 20px;
    width: 35px;
    height: 40px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
    line-height: 0px;

}

/* 标题样式 */
.txt-title {
    width: 280px;
    padding-left: 28px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 32px;
    font-family: PingFang SC;
    line-height: 48px;
    font-weight: bold;
    color: #282828;
}

.img {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.img img {
    width: 100%;
}

.rel {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}

.rel_fullName {
    font-size: 1.6rem;
    font-family: PingFang SC;
    color: #333333;
    width: 30%;
    padding-left: 15px;
}

.rel_selfPhone {
    width: 55%;
    font-size: 1.6rem;
    font-family: PingFang SC;
    color: #333333;
}

.rel_userNum {
    font-size: 1.6rem;
    font-family: PingFang SC;
    color: #333333;
    width: 15%;
}

/* .record {
    text-align: center;
    font-size: 2.4rem;
    font-family: PingFang SC;
    color: #333333;
    margin-bottom: 20px;
    background-color: #ffffff;
} */

.mtbtn {
    width: 9rem;
    height: 3.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.69rem 1.3125rem;
    border-radius: 2rem;
}

.mtbtn a {
    text-decoration: none;
    color: #ffffff;
}

.mtheader img {
    line-height: 0;
}

.mtheader {
    height: 5.6rem;
    font-size: 2rem;
}

.tag.cancel {
    color: #BFBFBF;
}

.tag.pay {
    color: #FC4A1A;
}

.tag.writeOff {
    color: #1686FC;
}

.tag.writeOffed {
    color: #52CC0C;
}

.pda {
    display: flex;
    justify-content: flex-end;
}


.btn_no_1 {
    width: 20%;
    height: 4rem;
    font-size: 1.5rem;
    color: #FC4A1A;
    background-color: #ffffff;
    border: 1px solid #FC4A1A;
    border-radius: 4.5625rem;
    margin-left: 20px;
}

.btn_no_2 {
    width: 20%;
    height: 4rem;
    font-size: 1.5rem;
    color: #1686FC;
    background-color: #ffffff;
    border: 1px solid #1686FC;
    border-radius: 4.5625rem;
}
</style>
  
  
  
  
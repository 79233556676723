var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"articleItem"},[_c('div',{staticClass:"articleItem_tit",domProps:{"textContent":_vm._s(_vm.activity.activityName)}}),_c('div',{staticClass:"articleItem_bri",domProps:{"textContent":_vm._s(`活动简介：${_vm.activity.activityIntroduction}`)}}),_c('div',{staticClass:"articleItem_timer",domProps:{"textContent":_vm._s(`活动时间：${_vm.parseTime(
      _vm.activity.activityStartTime,
      '{yyyy}-{mm}-{dd} {hh}:{ii}'
    )} - ${_vm.parseTime(
      _vm.activity.activityEndTime,
      '{yyyy}-{mm}-{dd} {hh}:{ii}'
    )}`
      )}}),_c('div',{staticClass:"articleItem_cont1 mgt",staticStyle:{"padding-bottom":"80px"},domProps:{"innerHTML":_vm._s(_vm.activity.activityContent)}})]),_c('div',{staticClass:"footer"},[_c('div',{staticStyle:{"width":"100%","height":"5rem"}}),_vm._m(0),(Boolean(_vm.allowRolesComputed))?_c('span',{staticClass:"fot2",domProps:{"textContent":_vm._s(`(该活动仅限于${_vm.allowRolesComputed}参加)`)}}):_vm._e(),_c('span',{staticClass:"fot3"},[_vm._v("注：活动开始前"+_vm._s(_vm.hours)+"小时，报名结束")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"writeoff"},[_c('span',[_vm._v("出示二维码，扫码进场")])])
}]

export { render, staticRenderFns }
<template>
  <div class="back_img">
    <img class="sign_img" src="../../assets/sign/icon2.png" alt="" @touchstart.prevent="touchin()" />
    <img class="sign_img1" src="../../assets/Home/lin.png" alt="" />
    <div class="flex-col align-center">
      <input class="back_inp" type="text" v-model="num" :state="numState" placeholder="请输入手机号码" maxlength="11"
        onfocus="this.placeholder=''" onblur="this.placeholder='请输入手机号'" onkeyup="this.value=this.value.replace(/\D/g,'')"
        @blur="checkblur" />
      <p class="inp_pp" ref="tip"></p>
    </div>
    <div class="back_btn">
      <button class="back_btn1" @click="login">登录</button>
    </div>
    <div class="back_btn">
      <button class="back_btn1" @click="wxLogin">注册</button>
    </div>
    <div class="back_d">
      <p>{{ this.list[0] }}</p>
      <p>{{ this.list[1] }}</p>
      <p>{{ this.list[2] }}</p>
      <p>{{ this.list[3] }}</p>
    </div>
    <!-- 核验员登录 -->
    <el-dialog title="核验员登录" :visible.sync="loginDialog" width="84%" :close-on-click-modal="false">
      <div class="inputItem">
        <div class="imgBox">
          <img :src="userImg" alt="" style="height:100%" />
        </div>
        <input class="inputBox" v-model="loginInfo.adminName" type="text" placeholder="请输入账号" />
      </div>

      <div class="inputItem">
        <div class="imgBox">
          <img :src="passImg" alt="" style="height:100%;width:75%;margin:0 auto;" />
        </div>
        <input ref="pwd" class="inputBox" v-model="loginInfo.adminPass" type="password" placeholder="请输入密码" />
      </div>
      <div class="submitBtn" @click="hxyLogin">提交</div>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { getAccountInfo, wxLogin, hxLogin } from '@/api/user'
export default {
  data() {
    return {
      //图片
      userImg: require("../../assets/sign/user.png"),
      passImg: require("../../assets/sign/pass.png"),
      list: ['1、首次进入需要登录绑定账号绑定后下次直接进入', '系统首页若账号退出重新进入需要再次输入账号', '2、一个账号对应一个角色:注册选择角色时', '请确认无误后点击注册按钮'],
      num: '',
      numState: '',
      url1: '',
      //核销员弹窗
      loginDialog: false,
      //用户信息
      loginInfo: {
        adminName: '',
        adminPass: '',
        client: "公众号",
      },
      pr: false,
      ps: false,
    }
  },
  methods: {
    //点击登录按钮实现拿取数据进行登录
    async login() {
      let that = this
      // 我写的
      // const res = await getAccountInfo({ adminName: this.num })
      // console.log(res);
      // 原来的代码
      if (/^1[0-9]{10}$/.test(this.num)) {
        const res = await getAccountInfo({ adminName: this.num })
        const { msg, data, code } = res.data
        if (code === 1001) {
          if (!data) {
            console.log(data);
            this.$dialog.confirm({
              title: '提示',
              message: '账号不存在',
              confirmButtonText: '前往注册',
              beforeClose(action, done) {
                if (action === 'confirm') {
                  wxLogin()
                  // that.$router.push('/sign/register')
                }
                done()
              },
            })
          } else {
            wxLogin()
            // that.$router.push('/mine')
          }
        } else {
          this.$toast(msg)
        }
      } else {
        this.$refs.tip.innerHTML = '请输入正确的手机号'
      }
    },
    wxLogin() {
      wxLogin()
      // this.$router.push('') //(自己写的)
    },
    checkblur() {
      //失去焦点时判断
      let inp1 = document.getElementsByClassName('inp_pp')
      if (this.checknum() == true) {
        console.log('格式正确')
        inp1[0].innerHTML = ''
        this.url1 = '/'
      } else {
        console.log('格式错误')
        inp1[0].innerHTML = '请重新输入手机号'
        console.log(inp1[0].innerHTML)
      }
    },
    checknum() {
      let reg = /^0?13|14|15|18|17[0-9]{9}$/
      if (reg.test(this.num)) {
        this.numState = 'success'
        return true
      } else {
        this.numState = 'false'
        return false
      }
    },
    //长按事件3s触发
    touchin() {
      //再次清空定时器，防止重复注册定时器
      clearInterval(this.Loop);
      this.Loop = setTimeout(function () {
        //打开核销员登录弹窗
        this.loginDialog = true;
      }.bind(this), 1000);
    },
    //核销员登录
    async hxyLogin() {
      if (this.loginInfo.adminName && this.loginInfo.adminPass) {
        try {
          const { data } = await hxLogin(this.loginInfo);
          if (data.code === 1001) {
            console.log(data.data.roles[0].roleName, "登录人身份");
            console.log(!data.data.roles[0].roleName == "核销员", "456");
            if (data.data.roles[0].roleName == "核销员") {

            } else {

              this.$message.error("该用户不是核销员!");
              return
            }
            const findPersonModel = data.data.findPersonModel;
            const token = data.data.token;
            await this.$store.dispatch("setLoginInfo", { token: token, findPersonModel: findPersonModel });
            this.$router.push("/writeOff");
          } else {
            this.$message.error(data.msg);
          }
        } catch (error) {
          console.error("Error during login:", error);
        }
      } else {
        this.$message.error("账户或密码不能为空!");
      }
    },
    // 退出登录
    async loginOut() {
      await this.$store.dispatch("setLoginInfo", { token: '', findPersonModel: '' });
    },
  },
  created() {
    this.loginOut()
  }
}
</script>

<style scoped>
/* -------------wb */
.inputField {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

/*---------------- wb */



::v-deep .el-dialog {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 10%;
  border-radius: 14px;
  /* padding-bottom: 50px; */
}

::v-deep .el-dialog__title {
  font-weight: 600;
  font-family: " PingFang SC, PingFang SC";
}

::v-deep .el-dialog__header {
  padding-top: 40px;
}

::v-deep .el-dialog__body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

}

.back_btn:hover,
.back_btn1:hover {
  background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
}

.back_btn1:hover {
  color: #ffffff;
}

.back_btn1 {
  width: 100%;
  height: 100%;
  font-size: 3rem;
  color: #1686fc;
  background-color: #ffffff;
  border: 0;
  border-radius: 3rem;
}

/* 报错显示文字p标签 */
.inp_pp {
  color: red;
  font-size: 2rem;
}

/* 注册提交按钮 */
.back_d {
  text-align: center;
  line-height: 2.2rem;
  width: 75%;
  height: 10%;
  margin-top: 70px;
  font-size: 1.5rem;
  color: #ffffff;
}

/* btn登录 注册 按钮 */
.back_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  height: 3em;
  margin-top: 1rem;
  background-color: #ffffff;
  border-radius: 3rem;
}

.back_btn:hover,
.back_btn1:hover {
  background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
}

.back_btn1:hover {
  color: #ffffff;
}

.back_btn1 {
  width: 100%;
  height: 100%;
  font-size: 3rem;
  color: #1686fc;
  background-color: #ffffff;
  border: 0;
  border-radius: 3rem;
}

/* .back_inp::-webkit-input-placeholder{color: #FFFFFF;font-size: 34px;} */
.back_inp::placeholder {
  color: #ffffff;
}

.back_inp[type='text'] {
  color: #ffffff;
}

.back_inp {
  width: 85%;
  height: 3em;
  margin-top: 15%;
  border: 0.3rem solid #ffffff;
  background: url('../../assets/sign/icon.png') no-repeat 8%;
  background-color: transparent;
  background-size: 1.4em;
  box-shadow: 0 6.3125rem 1.875rem ragb(243, 115, 53, 0.1);
  opacity: 0.92;
  border-radius: 4.5625rem;
  text-indent: 4em;
}

.back_img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/sign/sign1.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/*弹窗登录样式*/
.inputItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  border-bottom: 1px solid #EDEDF5;
  margin: 20px auto;
  padding: 4px 0 4px 0;
}

.imgBox {
  width: 28px;
  height: 28px;
}

.inputBox {
  flex: 1;
  margin-left: 5px;
  font-size: 16px;
  font-family: "PingFang SC-Bold";
  font-weight: Bold;
  line-height: 0px;
  color: #282828;
  opacity: 1;
  line-height: 30px;
  /* background-color: transparent; */
  border-style: none;
}

input:focus {
  border-style: none;
  outline: none;
}

.wordWarn {
  margin-left: -12%;
  color: rgb(245, 81, 81);
}

.submitBtn {
  width: 90%;
  height: 50px;
  background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
  border-radius: 25px;
  margin: 15% auto;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  line-height: 48px;
}

.back_img>* {
  margin-left: auto;
  margin-right: auto;
}

.sign_img1 {
  width: 50%;
  margin-top: 2rem;
}

.sign_img {
  width: 6.25rem;
  height: 6.25rem;
  margin-top: 33%;
}

* {
  margin: 0;
  padding: 0;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import { Toast } from 'vant'
import router from '@/router/index'
Vue.use(Vuex)

import { getStorage, setStorage } from '@/utils/storage'

import { getUserInfo } from '@/api/user'

const state = {
  // http://zhangbx12.e2.luyouxia.net:22673   测试
  //正式服： http://60.205.148.204:10411
  // baseURL: 'http://zhangbx12.e2.luyouxia.net:22673',
  // baseURL: 'http://192.168.31.133:10411',
  baseURL: 'https://www.chuji.site:10412',
  isLogin: '0',
  ser: null,
  token: getStorage('token') || '',
  userInfo: getStorage('userInfo'),
  auditStatus: {
    待审核: "认证中",
    已通过: "已认证",
    未通过: "认证失败",
    未认证: "未认证",
  }
}

export default new Vuex.Store({
  state,
  getters: { //监听数据变化
    baseURL: state => state.baseURL,
    token: state => state.token,
    userInfo: state => state.userInfo,
    auditStatus: state => state.auditStatus
  },
  mutations: {//函数
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {//异步请求
    //微信登录回调
    async getUserInfo({ commit, state }, query = {}) {
      const { userInfo, token } = state
      const adminId = userInfo ? userInfo.adminId : query.id
      if (!adminId) { return Promise.reject() }
      if (!token && query.token) {
        commit('SET_TOKEN', query.token)
      }
      const res = await getUserInfo({ adminId });
      const { code, msg, data } = res.data;
      if (code === 1001) {
        const { findPersonModel, token } = data;
        commit('SET_TOKEN', token)
        commit('SET_USERINFO', findPersonModel)
        setStorage("token", token);
        setStorage("userInfo", findPersonModel);
        Promise.resolve(data)
      } else {
        Promise.reject(res.data)
        this.$toast.fail(msg);
      }
    },
    //核销员登录回调
    async setLoginInfo({ commit, state }, query = {}) {  
      const { findPersonModel, token } = query;  
      commit('SET_TOKEN', token);  
      commit('SET_USERINFO', findPersonModel);  
      setStorage("token", token);  
      setStorage("userInfo", findPersonModel);      
  },

    checkAuth({ commit, state }) {
      const { userInfo } = state
      if (!userInfo) {
        router.push('/sign')
        return false
      }

      const roleId = userInfo.roles[0].roleId
      if ('30 31'.includes(roleId)) return true // 家长及嘉宾是注册，没有审核流程

      if (userInfo.auditStatus === '已通过') return true

      if (userInfo.auditStatus === '待审核') {
        Toast('认证中')
      } else if (userInfo.auditStatus === '未通过') {
        router.push({
          28: "/sign/AuthenSch",
          29: "/sign/AuthenStaff",
          // 30: "/sign/AuthenGuest",
          // 31: "/sign/AuthenMom",
        }[roleId])
      }
      return false
    }
  },
  modules: { //子仓库
  }
})


import { render, staticRenderFns } from "./Writeoffce.vue?vue&type=template&id=3c97f03d&scoped=true&"
import script from "./Writeoffce.vue?vue&type=script&lang=js&"
export * from "./Writeoffce.vue?vue&type=script&lang=js&"
import style0 from "./Writeoffce.vue?vue&type=style&index=0&id=3c97f03d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c97f03d",
  null
  
)

export default component.exports
<template>
  <div>
    <div class="au_list_last">
        <button v-if="this.num!=0" class="art_btn" @click="showPopup">我要报名</button>
        <button v-else-if="this.numc-this.num!=1" class="art_btn">报名成功</button>
        <button v-else class="art_btn1" >已报满</button>
        <van-popup v-model="showp" get-container="#app" class="au_list_popup">
            <img
                class="pop_img"
                src="../assets/top_icon/icon_yes.png"
                alt=""
                srcset=""
                />
            <p>报名成功</p>
        </van-popup>
         <!-- <p v-if="this.num!=0">剩余席位:{{num}}</p>
         <p v-else style="color:#D5D5D5;">剩余席位:{{num}}</p> -->
    </div> 
  </div>
</template>

<script>
export default {
 data () {
     return {
         showp:false,
         num:1,
         numc:'num'
     }
 },
 methods: {
       // 提示弹出框
        showPopup() {
          if(this.num!=0){
            let counter=this.num;
            counter--;
            this.num=counter;
            this.showp = true;
          }
        }
 }
}
</script>

<style scoped>
.au_list_last {
  position: absolute;
  top: 86%;
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 11%;
}
.art_btn {
  width: 60%;
  height: 5.75rem;
  color: #ffffff;
  font-size: 2.375rem;
  background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
  border: 0;
  border-radius: 4.5625rem;
}
.art_btn1{
   width: 60%;
  height: 5.75rem;
  color: #ffffff;
  font-size: 2.375rem;
  background: gray;
  border: 0;
  border-radius: 4.5625rem;
}
.pop_img {
  margin-top: 5rem;
}
.au_list_popup {
  width: 60%;
  height: 20%;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #333333;
  border-radius: 2rem;
}

</style>
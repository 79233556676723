<template>
  <div class="pdt">
    <van-cell title="姓名：" :value="userInfo.fullName" />
    <van-cell title="性别：" :value="userInfo.adminSex" />
    <van-cell title="联系电话：" :value="userInfo.selfPhone" />
    <van-cell title="所在校区：" :value="userInfo.campus" class="mgt" />
    <van-cell title="工作状态：" :value="userInfo.workStatus" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo
    };
  }
};
</script>
<template>
  <div>
     
  </div>
</template>

<script>
export default {
    data () {
        return {
            list:[
                 { 
                    activityContent: "测试活动内容",
                    activityCreateTime: "2022-04-13 03:52:28",
                    activityEndTime: "2022-04-24 03:32:34",
                    activityId: 1,
                    activityIntroduction: "活动1说明",
                    activityName: "活动名称1",
                    activityStartTime: "2022-04-20 03:52:28",
                    adminId: 596,
                    coverUrl: "活动1的封面url",
                    fullName: "修改",
                    participantNum: 1,
                    participantsNumber: 11
                },
                { 
                    activityContent: "测试活动内容",
                    activityCreateTime: "2022-04-13 03:52:28",
                    activityEndTime: "2022-04-24 03:32:34",
                    activityId: 1,
                    activityIntroduction: "活动1说明",
                    activityName: "活动名称1",
                    activityStartTime: "2022-04-21 03:52:28",
                    adminId: 596,
                    coverUrl: "活动1的封面url",
                    fullName: "修改",
                    participantNum: 1,
                    participantsNumber: 11
                }
            ]
        }
    },
    methods: {
        
    },
    mounted () {
       console.log(this.reverseTime);
    },
    computed: {
        reverseTime:function(){
            this.list.forEach(e => {
                let tim = e.activityStartTime;
                console.log(tim);
            });
        }
    }
}
</script>

<style>

</style>
/**
 * 通用js方法封装处理
 */
import { isArray, isEmpty, isPlainObject } from './validate'
import Calendar from './calendar'
const baseURL = process.env.VUE_APP_BASE_API

// 日期格式化
export function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

export function N2S(val) {
	const e = String(val)
	let rex = /^([0-9])\.?([0-9]*)e-([0-9])/
	if (!rex.test(e)) return val
	const numArr = e.match(rex)
	const n = Number('' + numArr[1] + (numArr[2] || ''))
	const num = '0.' + String(Math.pow(10, Number(numArr[3]) - 1)).substr(1) + n
	return num.replace(/0*$/, '') // 防止可能出现0.0001540000000的情况
}

export function numberInput(target, key) {
	let val = this[target][key]
	val = val === void 0 ? '' : val + ''
	this[target][key] = val.replace(/[^0-9\.?]/g, '')
}

// 表单重置
export function resetForm(refName) {
	if (this.$refs[refName]) {
		this.$refs[refName].resetFields();
	}
}

export function resetData(key, option) {
	// return console.log(this)
	const { form = 'form', defaultData = 'defaultData' } = option || {},
		obj = this[defaultData](),
		formData = this[form]
	if (typeof key === "string") {
		formData[key] = obj[key];
	} else {
		Object.assign(formData, obj);
	}
	this.$message.warning('重置成功')
}

export function resetData2(form, def, key) {
	const obj = def();
	if (typeof key === "string") {
		form[key] = obj[key];
	} else {
		Object.assign(form, obj);
	}
	this.$message.warning('重置成功')
}

export function tableFormat(value, datas) {
	for (let d of datas) {
		if (d.value === value) {
			return d.label
		}
	}
	return value
}

// 添加日期范围
export function addDateRange(params, dateRange, propName) {
	let search = params;
	search.params = typeof (search.params) === 'object' && search.params !== null && !Array.isArray(search.params) ? search.params : {};
	dateRange = Array.isArray(dateRange) ? dateRange : [];
	if (typeof (propName) === 'undefined') {
		search.params['beginTime'] = dateRange[0];
		search.params['endTime'] = dateRange[1];
	} else {
		search.params['begin' + propName] = dateRange[0];
		search.params['end' + propName] = dateRange[1];
	}
	return search;
}

// 回显数据字典
export function selectDictLabel(datas, value) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (datas[key].dictValue == ('' + value)) {
			actions.push(datas[key].dictLabel);
			return true;
		}
	})
	return actions.join('');
}

// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
	var actions = [];
	var currentSeparator = undefined === separator ? "," : separator;
	var temp = value.split(currentSeparator);
	Object.keys(value.split(currentSeparator)).some((val) => {
		Object.keys(datas).some((key) => {
			if (datas[key].dictValue == ('' + temp[val])) {
				actions.push(datas[key].dictLabel + currentSeparator);
			}
		})
	})
	return actions.join('').substring(0, actions.join('').length - 1);
}

// 通用下载方法
export function download(fileName) {
	window.location.href = baseURL + "/common/download?fileName=" + encodeURI(fileName) + "&delete=" + true;
}

// 打开新窗口
export function openWindow(target, rewrite) {
	target = baseURL + target
	window.open(target)
}

// 字符串格式化(%s )
export function sprintf(str) {
	var args = arguments, flag = true, i = 1;
	str = str.replace(/%s/g, function () {
		var arg = args[i++];
		if (typeof arg === 'undefined') {
			flag = false;
			return '';
		}
		return arg;
	});
	return flag ? str : '';
}

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
	if (!str || str == "undefined" || str == "null") {
		return "";
	}
	return str;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
	let config = {
		id: id || 'id',
		parentId: parentId || 'parentId',
		childrenList: children || 'children'
	};

	var childrenListMap = {};
	var nodeIds = {};
	var tree = [];

	for (let d of data) {
		let parentId = d[config.parentId];
		if (childrenListMap[parentId] == null) {
			childrenListMap[parentId] = [];
		}
		nodeIds[d[config.id]] = d;
		childrenListMap[parentId].push(d);
	}

	for (let d of data) {
		let parentId = d[config.parentId];
		if (nodeIds[parentId] == null) {
			tree.push(d);
		}
	}

	for (let t of tree) {
		adaptToChildrenList(t);
	}

	function adaptToChildrenList(o) {
		if (childrenListMap[o[config.id]] !== null) {
			o[config.childrenList] = childrenListMap[o[config.id]];
		}
		if (o[config.childrenList]) {
			for (let c of o[config.childrenList]) {
				adaptToChildrenList(c);
			}
		}
	}
	return tree;
}

/**
 * 根据状态值获取状态描述
 * @param status
 */
export const getAuditStatusText = (status) => {
	return status === 0 ? '待审核' : status === 1 ? '审核通过' : '审核不通过'
}

export const deepClone = (obj) => {
	if (isPlainObject(obj) || isArray(obj)) {
		return JSON.parse(JSON.stringify(obj))
	}
	return obj
}
/**
 * 过滤空值
 * @param {*} target
 * @returns *
 */
export const clear = (target) => {
	if (isArray(target)) {
		let k, v
		for (k = target.length - 1; k >= 0; k--) {
			v = target[k]
			if (isArray(v) || isPlainObject(v)) {
				clear(v)
			}
			if (isEmpty(v)) {
				target.splice(k, 1)
			}
		}
	} else if (isPlainObject(target)) {
		Object.entries(target).forEach(([k, v]) => {
			if (isArray(v) || isPlainObject(v)) {
				clear(v)
			}
			if (isEmpty(v)) {
				delete target[k]
			}
		})
	}
	return target
}

/**
 * 格式化金钱
 * @param {*} val 原始金额
 */
export const toMoney = val => {
  //val 数字number
  const str = ((val / 100) * 100).toFixed(2) + ''
  const intSum = str.substring(0, str.indexOf('.')).replace(/\B(?=(?:\d{3})+$)/g, ',') //取到整数部分
  const dot = str.substring(str.length, str.indexOf('.')) //取到小数部分搜索
  return intSum + dot
}

export function isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}
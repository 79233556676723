<template>
  <div>
    {{timeout}}
  </div>
</template>

<script>
export default {
  data(){
    return {
      timeout:""
    }
  },
  methods:{
    dealWithTime(data) { // 获取当前时间
      let timeout = Date.parse(data);
      console.log(timeout);
    },
  },
  mounted() { 
    // 页面加载完后显示当前时间
    // this.dealWithTime(new Date())
    // 定时刷新时间
    this.timer = setInterval(()=> {
       this.dealWithTime(new Date()) // 修改数据date
    }, 60000),
    this.dealWithTime(new Date());
  }, 
  destroyed() {
    if (this.timer) {  // 注意在vue实例销毁前，清除我们的定时器
      clearInterval(this.timer);
    }
  }
}
</script>

<template>
  <div>
    <!-- 导航 -->
        <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0">
      <!-- 导航 start-->
      <div class="nav">
        <div>林荫街龙门阵</div>
      </div>
      <!-- 导航 end-->
      <!-- 抬头条 -->
      <!-- article  start-->
      <div style="height:2rem;background-color:#1988FC;margin-top: 11%; border:0;"></div>
      <div class="container">
        <div style="width:2%; border:0;"></div>
        <img class="con_img" src="../assets/top_icon/mine_tou.png" alt="">
        <div class="con_btn">
          <p>请登录</p>
          <router-link to="/sign">
            <button class="con_btn_tologo">请登录</button>
          </router-link>
        </div>      
      </div>
      <div style="height:1rem;background-color:#1988FC; border:0;"></div>
      <!-- article  end-->
      
     
  </div>
</template>

<script>
       
export default {
  data () {
    return {
      
    }
  },
  mounted(){
     (function (doc, win) {
            var docEl = doc.documentElement,
                    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
                    recalc = function () {
                        var clientWidth = docEl.clientWidth;
                        if (!clientWidth) return;
                        /*设置一个屏幕最大宽度临界值，当大于这个宽度时，字体也不放大了*/
                        if(clientWidth>=640){
                            docEl.style.fontSize = '16px';
                        }else{
                            /*750是设计稿的宽度，100是基数为了方便换算，也可以为20，或者浏览器默认值16(px)*/
                            docEl.style.fontSize = 16 * (clientWidth / 750) + 'px';
                        }
                    };
 
            if (!doc.addEventListener) return;
            win.addEventListener(resizeEvt, recalc, false);
            doc.addEventListener('DOMContentLoaded', recalc, false);
        })(document, window);
  }
  ,methods: {
    
  }
}
</script>

<style scoped>
/* 面板 */
.container{
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #1988FC;
  margin: -0.01rem
}
.con_btn{
  display: flex;
  width: 85%;
  flex-direction: column;
}
.con_img{
  width: 7.125rem;
}
.con_btn p{
    width: 10rem;
    height: 3rem;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 3rem;
    margin: 0.5rem 0 0 1rem;
    color: #FFFFFF;
}
.con_btn_tologo{
    margin: 0.2rem 0 0 1rem;
    width: 7rem;
    height: 3rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #1988FC;
    background-color: #E0E4F8;
    border-radius: 2rem;
    border: 0;
}
/* 导航栏 */
.nav{
  position: fixed;
  top: 0;
  width: 100%;
  height: 5.5rem;
  font-size: 2rem;
  color: #FFFFFF;
  background-color: #1988FC;
  
}
.nav div{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%,-50%);/* 居中显示 */
}
*{margin: 0; padding: 0;}
</style>
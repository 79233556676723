<template>
<!-- author:潘浩
     function realization:test API
     time:2022/5/17
 -->
  <div>
      <button style="color:red;" @click="getToken">测试登录接口</button><br/>
      <button style="color:red;" @click="getTweet">测试推文接口</button><br/>
      <button style="color:red;" @click="getActive">测试活动管理接口</button><br/>
      <button style="color:red;" @click="getWx">测试微信授权接口</button><br/>
      <button style="color:red;" @click="getEAP">测试导出人员活动接口</button><br/>
      <button style="color:red;" @click="addAdmin">测试注册账号接口</button><br/>
      <button style="color:red;" @click="selectArt">测试根据活动id查询详情活动详情接口</button><br/>
      <button style="color:red;" @click="adoptExamine">测试通过审核接口</button><br/>
      <button style="color:red;" @click="rejectExamine">测试驳回审核接口</button><br/>
      <button style="color:red;" @click="findAdminByAdminId">测试查询账号信息接口</button><br/>
      <button style="color:red;"  @click="getActivityListPage">测试分页查询活动列表接口</button><br/>
      <button @click="activity">测试查询活动</button><br/>
      <button  @click="addTweet">测试添加活动人员接口</button><br/>
      <button style="color:red;"  @click="findActivityPeoplePage">分页查询活动人员列表</button><br/>
      <button  @click="updateAdmin">修改账号信息</button><br/>
  </div>
</template>

<script>
export default {
    data () {
        return {
            
        }
    },
    methods: {
        // 修改账号信息
        updateAdmin(){
            this.instance.updateAdmin({
                "adminId":908,
                "adminInfo":{
                    "auditStatus":"待审核",
                    "campus":"七中高新校区",
                    "adminSex":"男",
                    "adminInfoId": 228,
                    "selfPhone":18984132132,
                    "className":"好让拖后腿肉",
                    "fullName":"梵蒂冈你吃过饭呢"
                },
                "adminName":"梵蒂冈你吃过饭呢",
                "roleIds":[28]
            }).then(res=>{
                console.log(res);
            }).catch(err=>{
                console.log(err);
            })
        },
        // 分页查询活动人员列表
        findActivityPeoplePage(){
            this.instance.findActivityPeoplePage({
                current:1,
                parameters:{"activityId":"20"},
                size:40
            }).then(res=>{
                console.log(res);
            }).catch(err=>{
                console.log(err);
            })
        },
        activity(){//查询活动
            this.instance.getActivityDetailById({
                activityId:1
            }).then(res=>{
                console.log(res);
            }).catch(err=>{
                console.log(err);
            })
        },
        // 添加活动人员
        addTweet(){
            this.instance.addTweet({
              "activityId": 1,
              "activityParticipantsId": 1,
              "adminId": 1
            }).then(res=>{
                console.log(res);
            }).catch(err=>{
                console.log(err);
            })
        },
        //分页查询活动列表
        getActivityListPage(){
            this.instance.getActivityListPage({
                size:16,
                current:1
            }).then(res=>{
                console.log(res);
                console.log(res.data.data.records);
            })
        },
        // 查询账号信息
        findAdminByAdminId(){
            this.instance.findAdminByAdminId({
                adminId:904
            }).then(res=>{
                console.log(res);
            })
        },
        // 驳回审核
        rejectExamine(){
            this.instance.rejectExamine({
                adminId:605,
                remarks:'审核是否成功'
            }).then(res=>{
                console.log(res);
            })
        },
        //通过审核
        adoptExamine(){
            this.instance.adoptExamine({
                adminId: 605,
                remarks: "审核成功"
            }).then(res=>{
                console.log(res);
            })
        },
        // 根据活动id查询详情活动详情
        selectArt(){
            this.instance.getActivityDetailById({
                activityId:1
            }).then(res=>{
                console.log(res);
            })
        },
        // 注册账号
        addAdmin(){
            this.instance.addAdmin({
                "adminInfo":{
                  "auditStatus":"待审核",
                  "adminSex":"女",
                  "fullName":"ceshi16",
                  "campus":"七中高新校区",
                  "selfPhone":"18988888888",
                  "className":"舅服你哦忘了呢日日佛号翁斐然",
                  "workStatus":"退休"
                },
                "adminName":"ceshi16",
                "roleIds":[28]
            }).then(res=>{
                console.log(res);
            })
        },
        // 导出人员活动
        getEAP(){
            this.instance.userexportActivityPeople({
                parameters:{"activityId":"1","roleIds":[29]},
                responseType:'blob'
            }).then(res =>{
                console.log('请求成功',res);
                console.log(res.data);
                const link = document.createElement('a');
                let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                link.style.display = 'none';
                link.href = URL.createObjectURL(blob);
                link.download = '活动人员名单下载'; //下载的文件名
                document.body.appendChild(link);//添加
                link.click();
                document.body.removeChild(link);//释放URL 对象
            }).catch(err=>{
                console.log('请求失败',err);
            })
        },
        // 获取微信授权
        getWx(){
            this.instance.wxget({ 
            }).then(res=>{
                console.log(res);
            })
        },
        // 获取活动管理接口
        getActive(){
            this.instance.userActive({
                activityId:1
            }).then(res=>{
                console.log(res);
                console.log(res.data.data);
            })
        },
        // get 获取tweet列表 Done
        getTweet(){
            this.instance.userTweet({
                size:10, 
                current:1,
                parameters:{" ":" "}
            }).then(res=>{
                console.log(res);
            }).catch(err=>{
                console.log(err);
            })

        },
        // 请求登录获取token Done
        getToken(){//获取token
            this.instance.log({
                "adminName": "www",
                "adminPass": "123456",
                "client": "管理端"
            }).then(res=>{
                console.log(res);//显示未提交token
                 // 测试存入vuex localStorage中
                if(res.data.code===1001){
                    var data=res.data.data;
                    this.$store.commit('$_setToken',data.token);
                    console.log('已经存入本地');
                }
            }).catch(err=>{
                console.log(err);
            })
        }
    },
    mounted () {
       
    }
}
</script>

<style>

</style>
<template>
  <div class="pdt">
    <van-cell title="姓名：" :value="userInfo.fullName" />
    <van-cell title="性别：" :value="userInfo.adminSex" />
    <van-cell title="联系电话：" :value="userInfo.selfPhone" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo,
    };
  },
};
</script>
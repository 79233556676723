<template>
  <!--二维码扫描-->
  <div class="page-scan">
    <div class="scan-box">
      <video ref="video" id="video" class="scan-video" autoplay></video>
      <audio ref="audioRef">
        <source src="../../assets/mp3/scanCodeSuccess.mp3" type="audio/mp3">
      </audio>
      <div class="qr-scanner">
        <div class="box">
          <div class="line"></div>
          <div class="angle"></div>
        </div>
      </div>
      <div class="scan-tip">{{ scanTextData.tipMsg }}</div>
    </div>
  </div>
</template>

<script>
import { BrowserMultiFormatReader } from "@zxing/library";
import { confirmEnter } from '@/api/user'
export default {
  name: "scanCodePage",
  data() {
    return {
      scanTextData: {
        codeReader: null,
        tipMsg: "识别二维码",
        // 这个，就是当前调用的摄像头的索引，为什么是6，我会在后面说的 华为手机是鸿蒙系统有8个摄像头
        num: 5,
        // 这个就是扫描到的摄像头的数量
        videoLength: ""
      },
      hasBind: false,
      //活动id
      activityId: "",
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    //创建一个新的BrowserMultiFormatReader实例
    this.scanTextData.codeReader = new BrowserMultiFormatReader();
    // 打开摄像头
    this.openScan();
  },
  watch: {
    //根据val的值来判断是否需要关闭摄像头或重新打开并扫描二维码
    show(val) {
      if (!val) {
        // 关闭摄像头
        //判断是否成功获取指定id的video元素
        if (!document.getElementById("video")) {
          alert("请授权");
          return;
        }
        //获取video标签的ref属性
        let thisVideo = document.getElementById("video");
        //停止摄像头的视频轨道
        thisVideo.srcObject.getTracks()[0].stop();
        //重置QR码阅读器的状态
        this.scanTextData.codeReader.reset();
      } else {
        if (this.scanTextData.codeReader === null) {
          //QR码阅读器尚未初始化，需要创建一个新的BrowserMultiFormatReader实例
          this.scanTextData.codeReader = new BrowserMultiFormatReader();
        }
        //打开摄像头并开始扫描二维码
        this.openScan();
      }
    }
  },
  methods: {
    async openScan() {
      //获取可用的摄像头设备列表，并返回一个Promise对象
      this.scanTextData.codeReader.getVideoInputDevices().then(videoInputDevices => {
        // 默认获取第一个摄像头设备id
        let firstDeviceId = videoInputDevices[0].deviceId;
        console.log("手机摄像头的数量", videoInputDevices.length, videoInputDevices);
        // 获取第一个摄像头设备的名称
        const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
        if (videoInputDevices.length > 1) {
          // 华为手机有6个摄像头，前三个是前置，后三个是后置，第6个摄像头最清晰
          if (videoInputDevices.length > 5) {
            firstDeviceId = videoInputDevices[5].deviceId;
          } else {
            // 判断是否后置摄像头
            if (videoInputDeviceslablestr.indexOf("back") > -1) {
              firstDeviceId = videoInputDevices[0].deviceId;
            } else {
              firstDeviceId = videoInputDevices[1].deviceId;
            }
          }
        }
        //传入摄像头的设备id，开始从视频流中解码二维码
        this.decodeFromInputVideoFunc(firstDeviceId);
      })
        .catch(err => {
          console.error(err);
        });
    },
    //指定的视频流中连续解码二维码
    decodeFromInputVideoFunc(firstDeviceId) {
      //将QR码阅读器的状态重置为初始状态
      this.scanTextData.codeReader.reset();
      //每当QR码阅读器成功解码一个二维码时，并将解码结果和错误信息作为参数传入
      this.scanTextData.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId, "video", (result, err) => {
        //是否成功解码出二维码
        if (result && result.text) {
          //处理解码结果
          this.handleResult(result.text);
        }
        if (err && !err) {
          console.log(err);
          //弹出提示框显示错误信息
          this.$toast.fail(err);
        }
      }
      );
    },
    //成功解析出二维码信息，来回调处理逻辑
    //其他都定死，只有这为结果逻辑处理函数
    async handleResult(scanResult) {
      const { key } = this.$route.query;
      this.activityId = this.getSession(key).activityId;
      try {
        //判断扫码结果是否有activityId并且和选中的活动相同
        if (JSON.parse(scanResult).activityId && this.activityId === JSON.parse(scanResult).activityId) {
          const res = await confirmEnter(JSON.parse(scanResult).activityParticipantsId);
          // 扫码成功后播放音频并且关闭摄像头
          this.$refs.audioRef.play()
          this.scanTextData.codeReader.reset();
          await this.$dialog.confirm({ title: "扫码成功", message: `${JSON.parse(scanResult).userNum}人`, showCancelButton: false });
          // 弹窗关闭后再打开摄像头
          this.openScan()
          // this.$router.push({ path: '/writeOff' })
        } else {
          await this.$dialog.confirm({ message: "该二维码非本活动二维码！！！", showCancelButton: false });
          this.$router.push({ path: '/writeOff' })
        }
      } catch (error) {
        await this.$dialog.confirm({ message: "该二维码非本活动二维码！！！", showCancelButton: false });
        this.$router.push({ path: '/writeOff' })
      }
    }
  },
  destroyed() {
    // 重置摄像头
    this.scanTextData.codeReader.reset();
  }
};
</script>

<style scoped>
.scan-box {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background-image: linear-gradient(0deg,
      transparent 24%,
      rgba(32, 255, 77, 0.1) 25%,
      rgba(32, 255, 77, 0.1) 26%,
      transparent 27%,
      transparent 74%,
      rgba(32, 255, 77, 0.1) 75%,
      rgba(32, 255, 77, 0.1) 76%,
      transparent 77%,
      transparent),
    linear-gradient(90deg,
      transparent 24%,
      rgba(32, 255, 77, 0.1) 25%,
      rgba(32, 255, 77, 0.1) 26%,
      transparent 27%,
      transparent 74%,
      rgba(32, 255, 77, 0.1) 75%,
      rgba(32, 255, 77, 0.1) 76%,
      transparent 77%,
      transparent);
  background-size: 3rem 3rem;
  background-position: -1rem -1rem;
}

.scan-video {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.qr-scanner .box {
  width: 213px;
  height: 213px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border: 0.1rem solid rgba(0, 255, 51, 0.2);
  /* background: url('http://resource.beige.world/imgs/gongconghao.png') no-repeat center center; */
}

.qr-scanner .line {
  height: calc(100% - 2px);
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #00ff33 211%);
  border-bottom: 3px solid #00ff33;
  transform: translateY(-100%);
  animation: radar-beam 2s infinite alternate;
  animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
  animation-delay: 1.4s;
}

.qr-scanner .box:after,
.qr-scanner .box:before,
.qr-scanner .angle:after,
.qr-scanner .angle:before {
  content: "";
  display: block;
  position: absolute;
  width: 3vw;
  height: 3vw;
  border: 0.2rem solid transparent;
}

.qr-scanner .box:after,
.qr-scanner .box:before {
  top: 0;
  border-top-color: #00ff33;
}

.qr-scanner .angle:after,
.qr-scanner .angle:before {
  bottom: 0;
  border-bottom-color: #00ff33;
}

.qr-scanner .box:before,
.qr-scanner .angle:before {
  left: 0;
  border-left-color: #00ff33;
}

.qr-scanner .box:after,
.qr-scanner .angle:after {
  right: 0;
  border-right-color: #00ff33;
}

@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.scan-tip {
  width: 100vw;
  text-align: center;
  margin-bottom: 5vh;
  color: white;
  font-size: 5vw;
  position: absolute;
  bottom: 50px;
  left: 0;
  color: #fff;
}

.page-scan {
  overflow-y: hidden;
}
</style>
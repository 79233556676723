var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"articleItem"},[_c('div',{staticClass:"articleItem_tit",domProps:{"textContent":_vm._s(_vm.activity.activityName)}}),_c('div',{staticClass:"articleItem_bri",domProps:{"textContent":_vm._s(`活动简介：${_vm.activity.activityIntroduction}`)}}),_c('div',{staticClass:"articleItem_timer",domProps:{"textContent":_vm._s(`活动时间：${_vm.parseTime(
      _vm.activity.activityStartTime,
      '{yyyy}-{mm}-{dd} {hh}:{ii}'
    )} - ${_vm.parseTime(
      _vm.activity.activityEndTime,
      '{yyyy}-{mm}-{dd} {hh}:{ii}'
    )}`
      )}}),_c('div',{staticClass:"articleItem_cont1 mgt",staticStyle:{"padding-bottom":"80px"},domProps:{"innerHTML":_vm._s(_vm.activity.activityContent)}})]),_c('div',{staticClass:"footer"},[_c('div',{staticStyle:{"width":"100%","height":"5rem"}}),_c('div',{staticClass:"footer_clo"},[(_vm.showBtnComputed)?_c('div',{staticClass:"router_btn rel"},[_c('button',{staticClass:"btn_no",class:[
          [
            'btnnologo_start',
            'btnnologo_done',
            'btnnologo_start',
            'btnnologo_done',
          ][_vm.activityStatusComputed],
        ],attrs:{"disabled":this.activityStatusComputed === 1},domProps:{"textContent":_vm._s(['我要报名', '已报满', '下载资料', '报名成功'][_vm.activityStatusComputed])},on:{"click":_vm.clickStatusBtn}}),_c('van-dialog',{attrs:{"title":_vm.title,"message":_vm.message,"show-confirm-button":true,"confirm-button-text":_vm.confirmText,"show-cancel-button":true,"cancel-button-text":_vm.cancelText},on:{"confirm":_vm.handleConfirm,"cancel":_vm.handleCancel},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('van-stepper',{attrs:{"min":1,"max":10},model:{value:(_vm.stepperValue),callback:function ($$v) {_vm.stepperValue=$$v},expression:"stepperValue"}})],1)],1):_vm._e()]),(Boolean(_vm.allowRolesComputed))?_c('span',{staticClass:"fot2",domProps:{"textContent":_vm._s(`(该活动仅限于${_vm.allowRolesComputed}参加)`)}}):_vm._e(),_c('span',{staticClass:"fot3"},[_vm._v("注：活动开始前"+_vm._s(_vm.hours)+"小时，报名结束")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
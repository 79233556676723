<template>
  <div class="pdt">
    <van-cell title="姓名：" :value="userInfo.fullName" />
    <van-cell title="性别：" :value="userInfo.adminSex" />
    <van-cell title="联系电话：" :value="userInfo.selfPhone" />
    <van-cell title="毕业时间：" :value="parseTime(userInfo.graduationTime, '{yyyy}-{mm}-{dd}')" class="mgt" />
    <van-cell title="所在校区：" :value="userInfo.campus" />
    <van-cell title="所在班级：" :value="userInfo.className" />
  </div>
</template>

<script>
export default {
  data() {
    return {
        userInfo: this.$store.getters.userInfo
    };
  },
};
</script>
<template>
  <div>
    <div class="articleItem">
      <div class="articleItem_tit" v-text="activity.activityName"></div>
      <div class="articleItem_bri" v-text="`活动简介：${activity.activityIntroduction}`"></div>
      <div class="articleItem_timer" v-text="`活动时间：${parseTime(
        activity.activityStartTime,
        '{yyyy}-{mm}-{dd} {hh}:{ii}'
      )} - ${parseTime(
        activity.activityEndTime,
        '{yyyy}-{mm}-{dd} {hh}:{ii}'
      )}`
        "></div>
      <div class="articleItem_cont1 mgt" style="padding-bottom: 80px" v-html="activity.activityContent"></div>
    </div>
    <!-- 文章详情 end -->

    <div class="footer">
      <div style="width: 100%; height: 5rem"></div>
      <div class="pay">
        <span>报名成功！请尽快缴费！</span>
      </div>
      <span v-if="Boolean(allowRolesComputed)" class="fot2" v-text="`(该活动仅限于${allowRolesComputed}参加)`"></span>
      <span class="fot3">注：活动开始前{{ hours }}小时,报名结束</span>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { loadMembers, loadActivityDetail } from "@/api/activity";
export default {
  data() {
    return {

      list: [],
      records: [], //查询到的该活动人员
      urlTo: "",
      showp: false,
      num: null, //参会人数
      count: 0, //参与人数
      participantNum: "",
      nowtimer: "",
      activityId: "",
      activity: {},
      baochi: "",
      prompt: "",//下方提示语
      addTweetCode: "", //判断是否报名成功
      roleName: "", //角色
      timerTF: "", //判断未登录时，活动是否结束
      timerTFend: "", //判断登录后，活动是否结束
      adminId: "",
      auditStatus: this.$store.getters.userInfo?.auditStatus,
      actDone: "", //sessStorage中时间判断按钮显示
      hasJoined: false,
      hours: "",
    };
  },
  computed: {
    showBtnComputed() {

      // 是否显示按钮
      if (this.activityStatusComputed === 2) {
        // 如果是认证中和认证失败不显示
        if (this.auditStatus == "待审核" || this.auditStatus == "未通过") {
          return false
        }
        return this.isAllowedRoleComputed;
      }

      return true;
    },
    isAllowedRoleComputed() {
      // 是否允许下载资料
      const userInfo = this.$store.getters.userInfo;
      return userInfo ? "28 29".includes(userInfo.roles[0].roleId) : false;
    },
    activityStatusComputed() {
      let { activityStartTime, participantNum, participantsNumber } =
        this.activity;
      activityStartTime = new Date(activityStartTime.replace(/-/g, "/"));
      activityStartTime.setDate(activityStartTime.getDate() - 1);
      if (new Date() < activityStartTime) {
        if (this.hasJoined) {
          return 3;
        }
        if (participantNum >= participantsNumber) {
          return 1;
        }
        return 0;
      } else {
        return 2;
      }
    },
    allowRolesComputed() {
      const { participantsRoles } = this.activity;
      return participantsRoles.map((role) => role.roleName).join("、");
    },
  },
  created() {
    const { key } = this.$route.query;
    this.activity = this.getSession(key);
    if (this.activity.isDiscard === "是") {
      this.prompt = "报名已取消"
    }
    this.loadActivityDetail();
    if (this.$store.getters.userInfo) {
      this.loadMembers();
    }
  },
  methods: {
    async loadActivityDetail() {
      const { activityId } = this.activity;
      const { data } = await loadActivityDetail({ activityId });
      if (data.code === 1001) {
        this.activity = data.data;
        const time = (new Date(this.activity.activityStartTime.replace(/\-/g, '/')).getTime()) - (new Date(this.activity.enrollEndTime.replace(/\-/g, '/')).getTime())
        this.hours = String(time / 3600000)
        this.setSession(this.$route.query.key, data.data);
      } else {
        this.$toast(data.msg);
      }
    },
    async loadMembers() {
      const res = await loadMembers({
        parameters: {
          activityId: this.activity.activityId,
          adminId: this.$store.getters.userInfo.adminId,
        },
      });
      const { msg, code, data } = res.data;
      if (code === 1001) {
        this.hasJoined = data.records.length > 0;
      }
    },
    async clickStatusBtn() {
      const { userInfo } = this.$store.getters;
      if (userInfo) {
        if (this.activityStatusComputed === 0) {
          const allowed = await this.$store.dispatch("checkAuth"); // 账号认证情况判断
          if (!allowed) return;

          const { participantsRoles } = this.activity,
            roleId = userInfo.roles[0].roleId;
          // 允许的角色判断
          if (!participantsRoles.some((role) => role.roleId == roleId)) {
            return Toast({
              type: "fail",
              message: "您不在范围内",
            });
          }
          await this.$dialog.confirm({ title: "报名", message: "确认报名？" });
          const params = {
            activityId: this.activity.activityId,
            adminId: userInfo.adminId,
          };
          console.log('测试11111111111111');
          const res = await this.instance.addTweet(params);
          console.log("resresresresresres", res);
          const { code, data, msg } = res.data;
          if (code === 1001) {
            Toast({
              type: "success",
              message: "报名成功",
            });
            this.loadMembers();
          } else {
            this.$toast(msg);
          }
          return;
        }

        if (this.activityStatusComputed === 2 && this.isAllowedRoleComputed) { // 活动下载资料
          return this.$router.push({
            path: "/ongoing/download",
            query: { sessionKey: this.$route.query.key },
          });
        }

        if (this.hasJoined) { // 已经报名
          return;
        }
      } else if (this.activityStatusComputed === 0) {
        this.$router.push("/sign");
      }
    },
    // 点击按钮添加活动人员
    addTweet() {
      this.adminId = sessionStorage.getItem("adminId");
      console.log(this.adminId);
      this.instance
        .addTweet({
          activityId: this.activity.activityId,
          activityParticipantsId: 1,
          adminId: this.adminId,
        })
        .then((res) => {
          this.addTweetCode = res.data.code;
          if (res.data.code == 1001) {
            console.log("添加活动人员-suc", res);
          } else if (res.data.code == 1000) {
            console.log("该角色不能参加该活动");
          } else {
            console.log("再次点击");
          }
        })
        .catch((err) => {
          console.log("添加活动人员-err", err);
        });
    },
    //分页查询该活动人员列表
    findActivityPeoplePage() {
      this.instance
        .findActivityPeoplePage({
          current: 1,
          parameters: { activityId: this.activity.activityId },
          size: 40,
        })
        .then((res) => {
          console.log("分页查询该活动人员列表", res);
          this.records = res.data.data.records;
          console.log(this.records);
          if (res.data.code == 1001) {
            console.log("处理成功");
            this.findPeople();
          } else if (res.data.code == 1000) {
            console.log("该角色不能参加该活动");
          }
        })
        .catch((err) => {
          console.log("分页查询该活动人员列表", err);
        });
    },
    // 遍历活动人员列表查询是有存在已报名账号
    findPeople() {
      this.adminId = sessionStorage.getItem("adminId");
      for (let i = 0; i < this.records.length; i++) {
        let arr = [];
        arr = this.records[i];
        if (this.adminId == arr.adminId) {
          //若在遍历后adminId与登录后用户adminId进行对比
          this.baochi = 1;
        } else {
          this.baochi = 0;
        }
      }
    },
  },
};
</script>
  
<style scoped>
/* 弹窗样式 */
.router_btn {
  width: 6em;
}

.btn_no {
  width: 100%;
  height: 4rem;
  font-size: 1.5rem;
  border: 0;
  border-radius: 4.5625rem;
}

.btn_logo {
  width: 30%;
  height: 4rem;
  font-size: 1.5rem;
  border: 0;
  border-radius: 4.5625rem;
}

/* 已登录 */
.btnlogo_start {
  margin-left: 10rem;
  color: #ffffff;
  background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
}

/* 未登录 */
.btnnologo_start {
  /**未登录 报名中 */
  color: #ffffff;
  background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
}

.pnologo_start {
  color: #5e5e5e;
  left: calc(100% + 2em);
  bottom: 0;
  white-space: nowrap;
}

.plogo_start {
  color: #5e5e5e;
  margin-left: 2rem !important;
}

.pnologo_done {
  margin-left: 11rem !important;
}

.btnnologo_done {
  /* color: #ffffff; */
  color: #5e5e5e;
  background-color: #d5d5d5;
  /* background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%); */
}

.btnnologo_end {
  color: #5e5e5e;
  background-color: #d5d5d5;
}

.art_d {
  width: 29%;
  height: 4rem;
  color: #5e5e5e;
  font-size: 1.5rem;
  background: #d5d5d5;
  border: 0;
  border-radius: 4.5625rem;
}

.art_b {
  width: 29%;
  height: 4rem;
  margin-left: 11rem;
  color: #ffffff;
  font-size: 1.5rem;
  background: linear-gradient(135deg, #66b2fd 0%, #1686fc 100%);
  border: 0;
  border-radius: 4.5625rem;
}

.pop_img {
  margin-top: 5rem;
}

.au_list_popup {
  width: 60%;
  height: 22%;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #333333;
  border-radius: 2rem;
}

.mint-msgbox {
  height: 24rem !important;
}

.mint-msgbox-title {
  font-size: 2rem;
}

/* footer */
.footer p {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 3rem;
  color: #1686fc;
}

/* ******************************************* */
.download {
  width: 11rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.5rem;
  color: #ffffff;
  background-color: #fdc830;
  border-radius: 2rem;
}

/* ******************************************** */
.fot3 {
  width: 100%;
  height: 2rem;
  font-size: 1.5rem;
  color: #fc4a1a;
  margin-top: 0.1rem;
}

.fot2 {
  width: 100%;
  height: 2rem;
  font-size: 1.5rem;
  color: #fc4a1a;
  margin-top: 0rem;
}

.fot4 {
  margin-left: 6%;
  width: 29%;
  height: 4rem;
  font-size: 1.5rem;
  font-weight: bold;
  padding-left: 0;
  padding: 0.875rem 3.625rem;
  border-radius: 2.125rem;
  color: #5e5e5e;
  border: 0;
  background-color: #d5d5d5;
}

.fot1 {
  margin-left: 36%;
  width: 14%;
  height: 2.5rem;
  font-size: 1.75rem;
  font-weight: bold;
  padding: 0.875rem 3.625rem;
  border-radius: 2.125rem;
  color: #ffffff;
  background-color: #1686fc;
  box-shadow: 0 0.375rem 1.875rem rgba(22, 134, 252, 0.37);
}

.footer_clo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer {
  /* position: relative; */
  width: 90%;
  height: 27rem;
  margin: 0 auto;
  border-top: 0.0625rem solid #ededf5;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

/* 文章詳情 */
.articleItem img {
  width: 90%;
  margin: 0 auto;
  display: block;
  padding: 1rem 0;
}

.articleItem_cont1 {
  width: 90%;
  font-size: 1.75rem;
  color: #333333;
  line-height: 3.4375rem;
  margin: 0 auto;
  word-wrap:break-word;
}

.articleItem_cont1 img {
  max-width: 100%;
}

.articleItem_timer {
  width: 90%;
  margin: 0 auto;
  padding-top: 1px;
  font-size: 1.75rem;
  color: #bfbfbf;
}

.articleItem_bri {
  width: 90%;
  margin: 0 auto;
  padding-top: 1rem;
  font-size: 1.75rem;
  color: #5e5e5e;
  word-wrap:break-word;
}

.articleItem_tit {
  width: 90%;
  color: #333333;
  font-size: 2.625rem;
  font-weight: bold;
  margin: 0 auto;
  padding-top: 2.125rem;
  word-wrap:break-word;
}

/* mtheader */
.mtheader {
  width: 100%;
  height: 5.5rem;
  font-size: 2rem;
  background-color: #ffffff;
  color: black;
}

.mtheader img {
  width: 3rem;
  height: 3rem;
}

.pay {
  padding-bottom: 25px;
  padding-top: 25px;
  padding-right: 5px;
  padding-left: 5px;
  margin: auto;
  color: #1686FC;
  font-family: PingFang SC;
  font-weight: 400;
  border: 1px solid #fff;
  width: 60%;
  background: rgb(232, 243, 254);
  border-radius: 10px 10px 10px 10px;

}
</style>